import React, { useState, useEffect } from "react";
import { FiChevronsDown } from "react-icons/fi";
import {
  Card,
  Row,
  Col,
  Dropdown,
} from "react-bootstrap";
import { IoEllipsisVertical } from "react-icons/io5";
import "../CardButton/CardButton.scss";
import { getCauchyData } from "../../Service/mapService";
import BootstrapTable from "react-bootstrap-table-next";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { useNavigate } from "react-router-dom";

const DownwardContinuationCard = (props) => {
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate()

  useEffect(() => {
    getDownwardContinuationlayers();
  }, []);

  const getDownwardContinuationlayers = async () => {
    getCauchyData().then(data => {
      setTableData(data.workflows); // data.layers
    })
  };

  const columns = [
    {
      dataField: "layerName",
      text: "Layer Name",
      sort: true,
    },
    {
      dataField: "created",
      text: "Created",
      sort: true,
      formatter: (row, index) => (
        new Date(row).toLocaleString('en-GB')
      ),
    },
    {
      dataField: "uploadedBy.name",
      text: "Uploaded By",
      sort: true,
    },
    {
      dataField: "fileType.name",
      text: "Type",
      sort: true,
    },
    {
      isDummyField: true,
      dataField: "actions",
      text: "Actions",
      sort: false,
      formatter: (row, index) => (
        <>
          <Dropdown>
            <DropdownToggle size="sm" className="text-600 btn-reveal">
              <IoEllipsisVertical />
            </DropdownToggle>
            <DropdownMenu right className="py-2 byod-dropdown">
            <DropdownItem onClick={() => navigate("/", { state: { addLayerToMap: true } })}>View on Map</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </>
      ),
    },
  ];

  const emptyDataMessage = () => {
    return <p style={{textAlign: 'center', margin: 'auto'}} className="p-4">No data available, please upload data</p>;
  };

  const handleSelect = (row, isSelect) => {
    let data = row;
    data["isSelect"] = isSelect;
    return props.setRowField(data);
  };

  const selectRow = {
    mode: "radio",
    bgColor: "#007B7F",
    onSelect: handleSelect,
    clickToSelect: true,
    hideSelectColumn: true,
  };

  return (
    <>
      <Card>
        <Card.Header as="h5">
          <Row>
            <Col xs={12} md={9}>
            <FiChevronsDown />
              <p className="px-2">Downward Continuation ({tableData.length})</p>
            </Col>
            <Col xs={6} md={3} style={{ textAlign: "end" }}>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <BootstrapTable
            bootstrap4
            keyField="layerName"
            data={tableData}
            columns={columns}
            bordered={false}
            noDataIndication={emptyDataMessage}
            selectRow={selectRow}
            defaultSorted={[{ dataField: 'created', order: 'desc' }]}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default DownwardContinuationCard;
