import React, { useState, useEffect, useRef } from "react";
import { FiDatabase } from "react-icons/fi";
import {
  Card,
  Button,
  Row,
  Col,
  Modal,
  Form,
  Spinner,
  Dropdown,
} from "react-bootstrap";
import { CgClose } from "react-icons/cg";
import { IoEllipsisVertical } from "react-icons/io5";
import "../CardButton/CardButton.scss";
import { uploadDataset, getDatasetUploadStatus, getBYODLayers, getByodFileTypes } from "../../Service/mapService";
import BootstrapTable from "react-bootstrap-table-next";
import mockTable from "../../Service/mockTable.json";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { toast } from "react-toastify";
import DownwardContinuationModal from "../DownwardContinuationModal/DownwardContinuationModal";
import { useNavigate } from "react-router-dom";

const CardButton = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [selectedTableRow, setSelectedTableRow] = useState(null);
  const [fileContents, setFileContents] = useState(null);
  const [handleDataName, setHandleDataName] = useState("");
  const [handleDataType, setHandleDataType] = useState("ers");
  const [datasetJobId, setDatasetJobId] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [timerId, setTimerId] = useState(null);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileTypeList, setFileTypeList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    props.setRowField({});
    const arr = [];
    getByodFileTypes().then(data => {
      data.map((fileType) => {
        return arr.push({
          value: fileType.name,
          label: fileType.name
        });
      });
    });

    setFileTypeList(arr);
    getBYODlayers();
  }, []);

  useEffect(() => {
    if (props.downwardContinuationActivate) {
      setOpenModal2(true);
    }
  }, [props.downwardContinuationActivate]);

  useEffect(() => {
    if (!datasetJobId) {
      clearInterval(timerId);
    }
  }, [datasetJobId]);

  const toggleModal = (e, open) => {
    e?.stopPropagation();
    setOpenModal(open);
  };

  const getBYODlayers = async () => {
    getBYODLayers().then(data => {
      setTableData(data.workflows);
    })
  };

  const handleUpload = (e) => {
    setFileContents(e.target.files);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      uploadDataset(fileContents, handleDataName, handleDataType).then((data) => {
        if (data.workflowId) {
          setDatasetJobId(data.workflowId);
          setTimerId(setInterval(() => getDatasetStatus(data.workflowId), 5000));
        }
      }).catch(err => {
        setFileContents(null);
        setHandleDataName("");
        setHandleDataType("");
        setLoading(false);
        toggleModal(null, false);
        console.error(err);
        toast.error("File Upload Failed");
      });
    }
    setValidated(true);
  };

  const getDatasetStatus = async (id) => {
    if (id) {
      getDatasetUploadStatus(id).then((data) => {
        if (data.workflow.status == "COMPLETED") {
          getBYODlayers();
          setDatasetJobId(null);
          setLoading(false);
          toggleModal(null, false);
          setFileContents(null);
          setHandleDataName("");
          setHandleDataType("");
          toast.success("Workflow Processing Complete");
        } else if (data.workflow.status == "FAILED") {
          setDatasetJobId(null);
          setLoading(false);
          toggleModal(null, false);
          setFileContents(null);
          setHandleDataName("");
          setHandleDataType("");
          toast.error("Workflow Processing Failed");
        }
      }).catch(err => {
        setDatasetJobId(null);
        setLoading(false);
        toggleModal(null, false);
        setFileContents(null);
        setHandleDataName("");
        setHandleDataType("");
        console.error(err);
        toast.error("Workflow Processing Failed");
      });
    }
  };

  const columns = [
    {
      dataField: "layerName",
      text: "File Name",
      sort: true,
    },
    {
      dataField: "lastUpdated",
      text: "Last Modified",
      sort: true,
      formatter: (row, index) => (
        new Date(row).toLocaleString('en-GB')
      ),
    },
    {
      dataField: "uploadedBy",
      text: "Uploaded By",
      sort: true,
      formatter: (row, index) => (
        row.name
      ),
    },
    {
      dataField: "fileType",
      text: "File Type",
      sort: true,
      formatter: (row, index) => (
        row.name
      ),
    },
    {
      isDummyField: true,
      dataField: "actions",
      text: "Actions",
      sort: false,
      formatter: (row, index) => (
        <>
          <Dropdown>
            <DropdownToggle size="sm" className="text-600 btn-reveal">
              <IoEllipsisVertical />
            </DropdownToggle>
            <DropdownMenu className="py-2 byod-dropdown">
              <DropdownItem onClick={() => navigate("/", { state: { addLayerToMap: true } })}>View on Map</DropdownItem>
              {index?.fileType?.name == 'tif' || index?.fileType?.name == 'ers' ? 
              <DropdownItem onClick={() => setOpenModal2(true)}>Downward Continuation</DropdownItem>: null}
            </DropdownMenu>
          </Dropdown>
        </>
      ),
    },
  ];

  const emptyDataMessage = () => {
    return <p style={{ textAlign: 'center', margin: 'auto' }} className="p-4">No data available, please upload data</p>;
  };

  const handleSelect = (row, isSelect) => {
    setSelectedTableRow(row);
    let data = row;
    data["isSelect"] = isSelect;
    props.setRowField(data);
  };

  const selectRow = {
    mode: "radio",
    bgColor: "#007B7F",
    onSelect: handleSelect,
    clickToSelect: true,
    hideSelectColumn: true,
  };

  return (
    <>
      <Card>
        <Card.Header as="h5">
          <Row>
            <Col xs={12} md={9}>
              <FiDatabase />
              <p className="px-2">Data ({tableData.length})</p>
            </Col>
            <Col xs={6} md={3} style={{ textAlign: "end" }}>
              <Button
                variant="primary"
                onClick={(e) => toggleModal(e, true)}
                className="btnYellow"
              >
                Upload Data
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <BootstrapTable
            bootstrap4
            keyField="id"
            data={tableData}
            columns={columns}
            bordered={false}
            noDataIndication={emptyDataMessage}
            selectRow={selectRow}
            defaultSorted={[{ dataField: 'lastUpdated', order: 'desc' }]}
          />
        </Card.Body>
      </Card>
      {openModal ? (
        <div>
          <Modal
            show={openModal}
            backdrop="static"
            keyboard={false}
            style={{ zIndex: "99999" }}
            className="styleModal"
          >
            <Modal.Header>
              <Modal.Title>Upload Data</Modal.Title>
              <Button onClick={(e) => toggleModal(e, false)} className="closeIcon">
                <CgClose />
              </Button>
            </Modal.Header>
            <Modal.Body>
              {datasetJobId || loading ? (
                <div style={{ textAlign: "center" }}>
                  <Spinner animation="grow" />
                  <div style={{ marginTop: "1em" }}>
                    {datasetJobId ? 'Processing' : 'Uploading'} data...please wait
                  </div>
                </div>
              ) : (
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="input"
                          name="name"
                          placeholder="Name"
                          required
                          value={handleDataName}
                          onChange={(e) => {
                            setHandleDataName(e.target.value);
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid name.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Type</Form.Label>
                        <Form.Select
                          required
                          value={handleDataType}
                          onChange={(e) => {
                            setHandleDataType(e.target.value);
                          }}
                        >
                          {fileTypeList.map((fileType) => {
                            return (
                              <option value={fileType.value}>{fileType.value}</option>
                            )
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Please select a file type.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Upload Files (ERS, GDB, GEOTIF) - Max size 0.5GB
                      </Form.Label>
                      <Form.Control
                        type="file"
                        name="fileName"
                        // accept={".ers"}
                        onChange={handleUpload}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        required
                        multiple
                      />
                      <Form.Control.Feedback type="invalid">
                        Please select a file.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Col md={12} style={{ textAlign: 'right' }}>
                      <Button
                        variant="primary"
                        className="btnBlack m-2"
                        onClick={(e) => toggleModal(e, false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        className="btnYellow"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>)}
            </Modal.Body>
          </Modal>
        </div>
      ) : null}
      <DownwardContinuationModal openModal={openModal2} toggleModal={setOpenModal2} row={selectedTableRow} />
    </>
  );
};

export default CardButton;
