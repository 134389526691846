import React, { useState,useEffect } from "react";
import Select, { components } from "react-select";
import "./CustomNode.scss";
import {checkIfValidUUID} from '../../Service/mapService'

const Menu = (props) => {
  const [optionValue, setOptionValue] = useState(null);

   useEffect(() => {
     props.options?.map((data) => {
      return setOptionValue(data)
     })
   }, [props.options, setOptionValue])
  return (
    <>
      <components.Menu {...props}>
      <div>
          <div>{props.children}</div>
          {checkIfValidUUID(optionValue?.id) ? 
          <button
          className={"change-data"}
          onClick={props.selectProps.changeOptionsData}
        >
          Add New
        </button>
        : null
          }
          {/* {props.selectProps?.type === 'RasterColorRamp' ?  // TODO: Constant
          <button
          className={"change-data"} // TODO: Change?
          onClick={props.selectProps.showEditColorRamp}
        >
          Edit
        </button>
        : null
          } */}
          
        </div>
      </components.Menu>
    </>
  );
};

const Option = (props) => {
  return (
    <>
      <components.Option {...props}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>{props.children}</div>
        </div>
      </components.Option>
    </>
  );
};

const CustomSelect = ({
  options,
  changeOptionsData,
  onChange,
  defaultValue,
  isDisabled,
  showEditColorRamp,
  type
}) => {
  return (
    <div>
      <Select
        options={options}
        components={{ Menu, Option }}
        defaultValue={defaultValue}
        changeOptionsData={changeOptionsData}
        onChange={onChange}
        isDisabled={isDisabled}
        showEditColorRamp={showEditColorRamp}
        type={type}
      />
    </div>
  );
};
export default CustomSelect;
