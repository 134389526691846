import React, { useEffect, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.control.opacity/dist/L.Control.Opacity.css";
import "leaflet.control.opacity";
import "../../Layer/SensoreTileLayer.js";
import {
  getLayerMap,
  getAuthorizationToken,
  checkIfValidUUID,
  getLayerAttributes,
  getAvailablePrintLayouts,
  getPrint,
  removeObjectNull
} from "../../Service/mapService";
import "leaflet-spin";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import editIcon from '../../assets/images/edit.svg';
import snapshotIcon from "../../assets/images/camera-icon.png";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Row, Col } from "react-bootstrap";
import { FaSpinner } from "react-icons/fa";
import MiniMap from 'leaflet-minimap';
require('leaflet-minimap/dist/Control.MiniMap.min.css');



export const MapPage = ({map, setMap, selectedNodes, layerStyleMap, layerFilterMap,setLayerBounds, setRemoveLayerBounds,setToastZoomLevel }) => {
  const MINZOOM = 4.8;
  const OpenStreetMap = "https://tile.openstreetmap.org/{z}/{x}/{y}.png";
  const openStreetMapId = "addddddb-b8ce-463d-9ecf-a4d719c11900";
  const satelliteImagery = "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png";
  const satelliteImageryId = "addddddb-b8ce-463d-9ecf-a4d719c16800"
  const [getTileURL, setTileURL] = useState("");
  const [getToken, setTokenMap] = useState(null);
  const [newData, setNewData] = useState([]);
  const [activeLayers, setActiveLayers] = useState([]);
  const [getLatitude, setLatitude] = useState(1.0);
  const [getLongitude, setLongitude] = useState(1.0);
  const [layerAttributes, setLayerAttributes] = useState([]);
  const [layerAttributesPosXY, setLayerAttributesPosXY] = useState(null); 
  const [enableDrawMode, setEnableDrawMode] = useState(false);
  const [enableDataAttribute, setEnableDataAttribute] = useState(false);
  const [enableModal, setEnableModal] = useState(false);
  const [printChange, setPrintChange] = useState('pdf');
  const [getCoordinates, setCoordinates] = useState(null)
  const [getlayerId, setLayerId] = useState(null)
  const [getOpacityPrint, setOpacityPrint] = useState(null)
  const [getselectedStyle, setSelectedStyle] = useState(null)
  const [getTemplate, setTemplate] = useState('782d7e52-b89f-4baa-be1a-77c71d9c1b85')
  const [getTemplateOptions, setTemplateoptions] = useState('')
  const [apiError, setApiError] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  
  const defaultErrorMessage = "An unexpected error occurred! Please select the different layer";

  const MINI_MAP_MIN_ZOOM = 2;
  const MINI_MAP_MAX_ZOOM = 3.4;
  const DISPLAY_CORD_DECIMAL_PLACES = 6;
  const handleClose = () => setEnableModal(false);

  useEffect(() => {
    var newMap = L.map("map", { pmIgnore: false }).setView(
      [-26.4391, 133.2813],
      5
    );
    //latlong display
    newMap.on("mousemove", function (e) {
      setLongitude(e.latlng.lng);
      setLatitude(e.latlng.lat);
    });
    newMap.on("drag zoomstart zoom zoomend", function () {
      setLayerAttributes([]);
      setLayerAttributesPosXY(null);
    });
    newMap.options.minZoom = MINZOOM;

    newMap.on('zoomstart zoom zoomend', function (ev) {
      setToastZoomLevel(newMap.getZoom());
    });

    newMap.on('mousemove', function() {
      setCoordinates(newMap.getBounds());
    });
    const ZoomViewer = L.Control.extend({
      onAdd() {
        const gauge = L.DomUtil.create("div");
        gauge.style.width = "100px";
        gauge.style.background = "rgba(0,0,0,0.90)";
        gauge.style.textAlign = "center";
        gauge.innerHTML = `Zoom level: ${newMap.getZoom()}`;
        newMap.on("zoomstart zoom zoomend", (ev) => {
          gauge.innerHTML = `Zoom level: ${newMap.getZoom()}`;
        });
        return gauge;
      },
    });

    new ZoomViewer({ position: "topright" }).addTo(newMap);

    // MiniMap
    var osm = new L.TileLayer(OpenStreetMap, {minZoom: MINI_MAP_MIN_ZOOM, maxZoom: MINI_MAP_MAX_ZOOM});

    var miniMap = new L.Control.MiniMap(osm, 
      {
        toggleDisplay: true, 
        aimingRectOptions: { color: "#ff7800", weight: 5 },
      }).addTo(newMap);

      const showDraw = L.Control.extend({
        onAdd() {
          const gauge = L.DomUtil.create("button");
          gauge.style.width = "30px";
          gauge.style.background = "rgba(30,21,29,1)";
          gauge.style.textAlign = "center";
          gauge.style.color = "#ffffff";
          gauge.innerHTML = `<img src=${editIcon} class="edit-icon alt="Edit Icon" />`;
          gauge.onclick = function () {
            newMap.pm.addControls({
              position: "topright",
              drawText: false,
              dragMode: false,
              rotateMode: false,
              cutPolygon: false,
              editMode: false,
              oneBlock: true,
              drawMarker: false,
            });
          };
          return gauge;
        },
      });
      new showDraw({ position: "topleft" }).addTo(newMap);
  

      newMap.pm.Toolbar.createCustomControl({
        name: "Drawdata",
        block: "custom",
        title: "Find Data attributes",
        className: "data-icon",
        onClick: function () {
          setEnableDataAttribute(true)
        },
        toggle:true
      });

      newMap.pm.Toolbar.createCustomControl({
        name: "Close",
        block: "custom",
        title: "Close the Controls",
        className: "close-icon",
        onClick: function () {
          newMap.pm.removeControls();
        }
      });
  
      newMap.pm.setGlobalOptions({
        showTooltip: { showTooltip: true, displayFormat: "metric" },
      });
      newMap.pm.Toolbar.changeControlOrder([
        "drawRectangle",
        "drawPolyline",
        "drawPolygon",
        "drawCircle",
        "drawCircleMarker",
      ]);
  
      newMap.on("pm:create", ({ layer, shape }) => {
        if (shape === "Rectangle") {
          setLayerBounds(layer);
        } else {
          setLayerBounds(null);
        }
      });
  
      newMap.on("pm:remove", ({ layer, shape }) => {
        if (shape === "Rectangle") {
          setRemoveLayerBounds(layer);
        } else {
          setRemoveLayerBounds(null);
        }
      });
  
      newMap.on("pm:globaldrawmodetoggled", ({ enabled }) => {
        setEnableDrawMode(enabled);
        setEnableDataAttribute(false);
      });
      
      const actions = [
        // uses the default 'cancel' action
        'cancel',
        'removeLastVertex',
        'finish',
        // creates a new action that has text, no click event
        { text: 'Find available datasets is only available for Rectangle' },
      ];
      const circleActions = [
        // uses the default 'cancel' action
        'cancel',
        // creates a new action that has text, no click event
        { text: 'Find available datasets is only available for Rectangle' },
      ];
      const customActions = [
        {
          text: 'cancel', 
          onClick: function () {
              setEnableDataAttribute(false)
              this.toggle(false)
          }
          },
      ];
      newMap.pm.Toolbar.changeActionsOfControl('Line', actions);
      newMap.pm.Toolbar.changeActionsOfControl('Polygon', actions);
      newMap.pm.Toolbar.changeActionsOfControl('Circle', circleActions);
      newMap.pm.Toolbar.changeActionsOfControl('CircleMarker', circleActions);
      newMap.pm.Toolbar.changeActionsOfControl('Drawdata', customActions)

          //print the page
    const PrintMap = L.Control.extend({
      onAdd() {
        const gauge = L.DomUtil.create("button");
        gauge.style.width = "30px";
        gauge.style.background = "rgba(30,21,29,1)";
        gauge.style.textAlign = "center";
        gauge.style.color = "#ffffff";
        gauge.innerHTML = `<img src=${snapshotIcon} class="edit-icon alt="Edit Icon" />`;
        gauge.onclick = function () {
          setEnableModal(true);
        };
        return gauge;
      },
    });

    new PrintMap({ position: "topleft" }).addTo(newMap);
      setMap(newMap);
  }, []);

  useEffect(() => {
    getLayerMap().then((data) => {
      return setTileURL(data);
    });
  }, []);

  useEffect(() => {
    getAuthorizationToken().then((data) => {
      return setTokenMap(data);
    });
  }, []);

//enable crosshair onclick of data-attribute icon in draw controls
useEffect(() => {
    if (enableDataAttribute) {
      let getClass = document.getElementsByClassName("leaflet-interactive");
      for (let i = 0; i < getClass.length; i++) {
        getClass[i].style.cursor = "crosshair";
      }
    } else {
      let getClass = document.getElementsByClassName("leaflet-interactive");
      for (let i = 0; i < getClass.length; i++) {
        getClass[i].style.cursor = "pointer";
      }
    }
  }, [enableDataAttribute]);

  const getFiltersParam = (filters) => {
    var filtersParam = "";
    filters.forEach(function (item, index) {
        var filterValue = (item.value?.value)?item.value?.value:item.value;
        if(item.dataCatalogueAttribute?.value && 
           item.operator?.value &&
           filterValue) {
            // Manually SQL escape chars:
            // - replace single quotes with double quote for 
            filterValue = filterValue.replaceAll("'","''");
            filterValue= encodeURIComponent(filterValue);
            filtersParam = filtersParam.concat(`"${item.dataCatalogueAttribute.columnName}"`);
            if(item.dataCatalogueAttribute.type === 'String' && filterValue.endsWith('*')  && item.operator.value === '=' ) {
              filtersParam = filtersParam.concat(` ILIKE '${filterValue.substr(0,filterValue.length -1)}%25'`);
            } else {
              filtersParam = filtersParam.concat(` ${item.operator.value}`);
              if(item.dataCatalogueAttribute.type === 'String' || item.dataCatalogueAttribute.type === 'Date') {
                filtersParam = filtersParam.concat(` '${filterValue}'`);
              } else {
                filtersParam = filtersParam.concat(` ${filterValue}`);
              }
            }
            if(item.operand?.value) {
              filtersParam = filtersParam.concat(` ${item.operand.value} `);
            }
        }
    });
    return filtersParam;
  };

  const getUrl = (el) => {
    var url = `${getTileURL}?layerId=${el.layerId}&crs=${el.crs}`;
    var appliedStyle = layerStyleMap[el.id];
    var filters = layerFilterMap[el.id];
    if (appliedStyle) {
      if (checkIfValidUUID(appliedStyle)) {
        url = url.concat(`&styleId=${appliedStyle}`);
      } else {
        url = url.concat(`&styleName=${appliedStyle}`);
      }
    }
    if (filters) {
      url = url.concat(`&filters=${getFiltersParam(filters)}`);
    }
    return url;
  };

  useEffect(() => {
    setNewData(
      selectedNodes.map((el) => ({
        url: getUrl(el),
        reference: `${el.reference}`,
        pyramidLevels: el.pyramidLevels,
        minZoomLevel: el.minZoomLevel,
        id: el.id,
        opacity: el?.sliderRange || 100,
        dataGeomMinA: el.dataGeomMinA,
        dataGeomMinB: el.dataGeomMinB,
        dataGeomMaxA: el.dataGeomMaxA,
        dataGeomMaxB: el.dataGeomMaxB,
        hasAttributes: el.hasAttributes,
        styleType:el.styleType
      }))
    );
  }, [getTileURL, selectedNodes]);


  useEffect(() => {
    if (map) {
      let newActiveLayers = [];
      activeLayers.forEach((layer) => {
        let dataFound = newData.find((data) => data.url === layer.url);
        if (!dataFound) {
          layer.layer.remove();
        }
      });
      newData.reverse().forEach((data) => {
        let layerFound = activeLayers.find((layer) => layer.url === data.url);
        if (data.id === openStreetMapId) {
          var displayeOpenStreet = new L.tileLayer(OpenStreetMap,{
            opacity: data.opacity / 100,
          });
          map.addLayer(displayeOpenStreet);
          displayeOpenStreet.bringToFront();
          newActiveLayers.push({
            url: OpenStreetMap,
            layer: displayeOpenStreet,
          });
        } else if(data.id === satelliteImageryId){
          var displaySatelliteImagery = L.tileLayer(satelliteImagery, {
            opacity:data.opacity / 100,
          });
          map.addLayer(displaySatelliteImagery)
          displaySatelliteImagery.bringToFront();
          newActiveLayers.push({
            url: satelliteImagery,
            layer: displaySatelliteImagery,
          });
        }
        else {
          if (!layerFound) {
            var layerMap = new L.TileLayer.sensoreTileLayer(
              data?.url,
              getToken,
              data?.reference,
              data?.pyramidLevels,
              {
                crs: L.CRS.EPSG4326,
                opacity: data.opacity / 100,
                minZoom: data?.minZoomLevel,
                bounds: [
                  new L.LatLng(data.dataGeomMinA, data.dataGeomMinB),
                  new L.LatLng(data.dataGeomMaxA, data.dataGeomMaxB),
                ],
                hasAttributes: data.hasAttributes,
              }
            );
            newActiveLayers.push({ url: data.url, layer: layerMap });
            layerMap.on("loading", function (event) {
              map.spin(true);
            });
            
            layerMap.on('load remove', function (event) {
              map.spin(false);
            });

            map.addLayer(layerMap);
            layerMap.bringToFront();
          } else {
            layerFound.layer.setOpacity(data.opacity / 100);
            layerFound.layer.bringToFront();
            newActiveLayers.push(layerFound);
          }
        }
      });
      setActiveLayers(newActiveLayers);
    }
  }, [newData]);

  const closeLayerAttributesPopup = ()  => {
    setLayerAttributes([]);
    setLayerAttributesPosXY(null);
  }

  const mouseDownCoords = e => {
    window.checkForDrag = e.clientX;
  };

  const clickOrDrag = e => {
    const mouseUp = e.clientX;
    if (
      mouseUp < window.checkForDrag + 5 &&
      mouseUp > window.checkForDrag - 5
    ) {
      handleAttributePopup(e)
    }
  };

  const handleAttributePopup = async (e) => {
    if (!enableDrawMode && enableDataAttribute) {
      setLayerAttributes([]);
      setLayerAttributesPosXY(null);
      let topLayer = activeLayers[activeLayers.length - 1];
      let urlParams = new URLSearchParams(
        "?" + topLayer.url.split("?")[1]
      );
      let topLayerId = urlParams.get("layerId");
      if (topLayerId && topLayer.layer?.options.hasAttributes) {
        let layerAttributeData = await getLayerAttributes(
          topLayerId,
          getLatitude,
          getLongitude,
          map.getZoom()
        );
        if (layerAttributeData.matches.length > 0) {
          var pageX = e.pageX;
          var pageY = e.pageY;
          if (e.pageY + 300 > e.view.innerHeight) {
            pageY = e.pageY - 300;
          }
          if (e.pageX + 250 > e.view.innerWidth) {
            pageX = e.pageX - 250;
          }
          setLayerAttributesPosXY({ x: pageX, y: pageY });
          setLayerAttributes(layerAttributeData.matches);
        }
      }
    }
  }

  //print functionality

  const handleSelect = (e) => {
    setPrintChange(e.target.value)
  }
  const handleTemplate = (e) => {
    setTemplate(e.target.value)
  }

  useEffect(()=>{
   setLayerId(newData.map(x => x.id).join(","))
   setOpacityPrint(newData.map(x => x.opacity || 100).join(','))
   let urlIds = newData.map(x => (x.styleType === 'RasterColorRamp' ? new URL(x?.url).searchParams.get('styleId') : ''))
   .map(value => value === 'undefined' ? '' : value);
   let joinedUrlIds = urlIds.join();
   setSelectedStyle(joinedUrlIds)
  },[selectedNodes, newData])

  useEffect(() =>{
    getAvailablePrintLayouts().then(result => {
      setTemplateoptions(result)
    })
  },[setTemplateoptions])

  const handleDownload = () => {
    setApiError('')
    setIsSearching(true);
    let params = {
      layers: getlayerId,
      styles: getselectedStyle,
      opacities: getOpacityPrint,
      geomMinA: getCoordinates?._southWest?.lat,
      geomMinB: getCoordinates?._southWest?.lng,
      geomMaxA: getCoordinates?._northEast?.lat,
      geomMaxB: getCoordinates?._northEast?.lng,
      template: getTemplate,
      format:printChange,
    };
    let paramsWithfilter = removeObjectNull(params);
    getPrint(paramsWithfilter).then((data) =>{
      setApiError('')
      setIsSearching(false);
      const url = window.URL.createObjectURL(data);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `file.${printChange}`);
			document.body.appendChild(link);
			link.click();
      setEnableModal(false)
    })     
     .catch((error) => {
      setIsSearching(false);
      setApiError(
        error &&
          error.response &&
          error.response.data["header"] &&
          error.response.data["header"]["errorText"]
          ? defaultErrorMessage +
              " : " +
              error.response.data["header"]["errorText"]
          : defaultErrorMessage
      );
    });
  }


  return (
    <>
      <div
        style={enableDataAttribute? {cursor:"crosshair", padding: 0, margin: 0, width: "100%", height: "100vh"}:{ padding: 0, margin: 0, width: "100%", height: "100vh", cursor:"grab" }}
        // ref={(el) => (mapContainer.current = el)}
        id="map"
        onMouseDown={e => mouseDownCoords(e)}
        onMouseUp={e => clickOrDrag(e)}
      ></div>
      <p className="placeLatLong">
        Lat/Lon {getLatitude.toFixed(DISPLAY_CORD_DECIMAL_PLACES)},{getLongitude.toFixed(DISPLAY_CORD_DECIMAL_PLACES)}
      </p>
      {!enableDrawMode && layerAttributes && layerAttributesPosXY && enableDataAttribute && <div className="layerAttributesPopup" style={{top: layerAttributesPosXY?.y + 'px', left: layerAttributesPosXY?.x + 'px'}}>
        <table>
          <thead>
            <tr>
              <th>Attribute</th>
              <th>Value</th>
              <th><div style={{textAlign: 'right'}}><AiOutlineCloseCircle onClick={closeLayerAttributesPopup} style={{cursor: 'pointer'}} /></div></th>
            </tr>
          </thead>
          <tbody>
            {layerAttributes.map((d, i) => Object.keys(d).map((a, i2) => 
              <tr key={i + i2}>
                <td>{a}</td>
                <td colSpan={2}>{d[a]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>}
      {enableModal && (
        <Modal
          show={enableModal}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Print Option</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Row>
           <Col>
              <Form.Group>
                <Form.Label>Select Template</Form.Label>
                <Form.Select aria-label="Default select example" onChange={handleTemplate} value={getTemplate} required>
                {getTemplateOptions?.map(data => {
                  return (
                    <option data-testid="select-template-options" value={data.id} key={data.id}>
                      {data.name}
                    </option>
                  )
                })}
                </Form.Select>
              </Form.Group>
           </Col>
           <Col>
            <Form.Group>
              <Form.Label>Select Format</Form.Label>
              <Form.Select aria-label="Default select example" onChange={handleSelect} value={printChange} required>
                <option value="pdf">PDF</option>
                <option value="png">PNG</option>
                <option value="jpeg">JPEG</option>
              </Form.Select>
            </Form.Group>
           </Col>
          </Row>
          </Modal.Body>
          <Modal.Footer className="downloadFooter">
            <Row>
              <Col md={6}>
                <p style={{color:"#842029",fontSize: "15px", marginBottom: "0px"}}>{apiError}</p>
              </Col>
              <Col md={6} style={{display:'flex'}}>
                <Button onClick={handleClose} style={{marginRight:"10px"}} className="btnBlack">Cancel</Button>
                <Button onClick={handleDownload} className="btnYellow">
                  {isSearching ? 
                  <FaSpinner /> :
                  'Download'
                  }
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
