import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getSearchAutoComplete, uuidv4 } from "../../Service/mapService";
import { Button, Form, Col, Row } from "react-bootstrap";
import {RiDeleteBin6Line, RiAddFill} from 'react-icons/ri';
import '../CustomNode/CustomNode.scss';

const CreateSearchFilter = (props) => {
  const [attributeList, setAttributeList] = useState([]);
  const [formFields, setFormFields] = useState([
    { dataCatalogueAttribute: {}, operator: "", value: "" },
  ]);
  
  const [autocompleteValues, setAutocompleteValues]= useState([])
  const [filterName, setFilterName] = useState("");
  const [filterId, setFilterId] = useState("");

  const operatorOptions = [
    { value: "=", label: "Equals" },
    { value: '!=', label: 'Not equal to' },
    { value: "<", label: "Less than" },
    { value: "<=", label: "Less than or equal to" },
    { value: ">", label: "Greater than" },
    { value: ">=", label: "Greater than or equal to" },
  ];

  const OPERAND_AND = { value: "AND", label: "AND" }; // Default operand
  const OPERAND_OR = { value: "OR", label: "OR" };
  const SELECT_DATA_CATALOGUE_ATTRIBUTE = 'dataCatalogueAttribute';

  useEffect(() => {
    const arr = [];
    props.filterAttributes.map((dataCatalogueAttribute) => {
      return arr.push({
        value: dataCatalogueAttribute.id,
        label: dataCatalogueAttribute.name,
        columnName: dataCatalogueAttribute.columnName,
        type: dataCatalogueAttribute.type,
      });
    });
    setAttributeList(arr);

    // Retrieve the saved search filters
    var cachedFormFields = props.layerFilterMap[props.node.id];
    if (!cachedFormFields) {
      cachedFormFields = [{}];
    }
    setFormFields(cachedFormFields);
    if(props.isNewSearchFilter) {
      setFilterName('New filter for ' + props.node.text);
      setFilterId(uuidv4())
    } else {
      setFilterName(props.node.text);
      setFilterId(props.node.id)

    }
  }, []);

  const handleFormChange = (event, index) => {
    let data = [...formFields];
    data[index][event.target.name] = event.target.value;
    setFormFields(data);
  };

  const handleSelectChange = (name, value, index) => {
    let data = [...formFields];
    data[index][name] = value;
    setFormFields(data);
    if(name === SELECT_DATA_CATALOGUE_ATTRIBUTE) {
      getAutoCompleteList('', index);
    }
  };

  const updateAutocompleteValues = (index, values) => {
    let data = [...autocompleteValues];
    data[index] = values;
    setAutocompleteValues(data);
  }

  const isFilterSet = (form) => {
    if (
      !form.dataCatalogueAttribute?.value ||
      !form.operator?.value ||
      !form.value
    ) {
      return false;
    }
    return true;
  };

  const submit = (e) => {
    e.preventDefault();
    if (formFields.length === 1 && !isFilterSet(formFields[0])) {
      delete props.layerFilterMap[filterId];
    } else {
      let filters = [];
      formFields.forEach(function (item, index) {
        // TODO: Validate the fields.
        if (isFilterSet(item)) {
          filters.push(item);
        }
      });

      var lastIndex = filters.length - 1;
      if (lastIndex >= 0) {
        filters.forEach(function (item, index) {
          if (index === lastIndex) {
            delete item["operand"];
          } else if (!item.operand?.value) {
            item["operand"] = OPERAND_AND;
          }
        });
        props.layerFilterMap[filterId] = filters;
      }
    }
    props.submitCreateFilters(filterId, filterName);
  };

  const addFields = () => {
    let object = { dataCatalogueAttribute: {}, operator: "", value: "" };
    setFormFields([...formFields, object]);
  };

  const removeFields = (index) => {
    let data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
    if (data.length === 0) {
      setFormFields([{ dataCatalogueAttribute: {}, operator: "", value: "" }]);
    }
  };

  const handleAutoCompleteInputChange = (inputValue, index) => {
    if(inputValue?.endsWith('*')) { // Wildcard search
      // setAutocompleteValues([]);
      updateAutocompleteValues(index,[])
      handleAutoCompleteChange({value: inputValue, label: inputValue}, index);
    } else {
      getAutoCompleteList(inputValue, index);
    }
  }

  const getAutoCompleteList = (inputValue, index) => {
    getSearchAutoComplete(formFields[index].dataCatalogueAttribute.value, inputValue)
    .then((data) => {
      var autocompleteValues = data.results.map(i=>(
        {
          label:i, 
          value:i
        }
      ));
      updateAutocompleteValues(index,autocompleteValues);
    })
  }

  const handleAutoCompleteChange = (value, index) => {
    if(value) {
      let data = [...formFields];
      data[index]['value'] = value;
      setFormFields(data);
    }
  }

  const handleNoOptions = (inputValue) => {
    if(inputValue?.endsWith('*')) {
      return 'Wildcard search';
    }
    return 'No matching name found';
  }

  return (
    <div className="createSearchFilter">
      <Form>
        <Form.Group className="mb-3" controlId="formCreateSearchFilter">
          <Row className="mt-2">
            <Col md>
              <p>{(props.isNewSearchFilter)?'New Search':'Edit'} Filter</p>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md>
              <Form.Label>Filter Name</Form.Label>
              <Form.Control
                type="string"
                // placeholder="New Style Name"
                style={{ backgroundColor: "#442040" }}
                required
                value={filterName || ""}
                //disabled={!props.isNewSearchFilter}
                onChange={(e) => {
                  setFilterName(e.target.value);
                }}
                onClick={(e) => {e.stopPropagation()}}
              />
            </Col>
          </Row>
          {formFields.map((form, index) => {
            return (
              <div key={index}>
                <Row className="mt-1 mb-3">
                  <Col md={4}>
                    <Select
                      inputId="dataCatalogueAttribute"
                      name="dataCatalogueAttribute"
                      placeholder="Select DataCatalogueAttribute"
                      options={attributeList}
                      isSearchable={true}
                      value={formFields[index].dataCatalogueAttribute}
                      onChange={(event) =>
                        handleSelectChange(
                          SELECT_DATA_CATALOGUE_ATTRIBUTE,
                          event,
                          index
                        )
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <Select
                      inputId="operator"
                      name="operator"
                      placeholder="Select"
                      options={operatorOptions}
                      value={formFields[index].operator}
                      onChange={(event) =>
                        handleSelectChange("operator", event, index)
                      }
                      required
                    />
                  </Col>
                  <Col md={4}>
                    {formFields[index].dataCatalogueAttribute?.type === 'String' ? 
                    <Select 
                      placeholder="Search Value"
                      isSearchable={true}
                      options={autocompleteValues[index]}
                      value={formFields[index].value}
                      onChange={(value) =>
                        handleAutoCompleteChange(value, index)
                      }
                      onInputChange={(value) => handleAutoCompleteInputChange(value, index)}
                      noOptionsMessage={({inputValue}) => handleNoOptions(inputValue) }
                    /> 
                    :
                    <Form.Control
                      type="text"
                      name="value"
                      placeholder="Enter value"
                      onChange={(event) => handleFormChange(event, index)}
                      value={formFields[index].value?.label?formFields[index].value.label:formFields[index].value}
                      required
                    />
                  }
                  </Col>
                  <Col md={1}>
                    <Button
                      onClick={() => removeFields(index)}
                      className="binIcon"
                    >
                      <RiDeleteBin6Line />
                    </Button>
                  </Col>
                  {index + 1 < formFields.length && (
                    <Col md={3} className='mt-2'>
                      <Select
                        name="operand"
                        options={[OPERAND_AND, OPERAND_OR]}
                        value={
                          formFields[index].operand
                            ? formFields[index].operand
                            : OPERAND_AND
                        }
                        onChange={(event) =>
                          handleSelectChange("operand", event, index)
                        }
                      />
                    </Col>
                  )}
                  {index + 1 == formFields.length && (
                    <Col md={1}>
                      <Button onClick={addFields} className="addIcon">
                        <RiAddFill/>
                      </Button>
                    </Col>
                  )}
                </Row>
              </div>
            );
          })}
        </Form.Group>
      </Form>

      <br />
      <Button className="searchLayer" onClick={submit}>
        Save Search Filter
      </Button>
    </div>
  );
};

export default CreateSearchFilter;
