import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { postAuthetication } from '../../Service/mapService';
import { setAuthToken } from "../../Service/SetAuthToken"
import './LoginForm.scss'

const LoginForm = ({ hasLabel}) => {
  
  // State
  const [formData, setFormData] = useState({
    name: '',
    password: '',
    remember: false
  });
  const [errorResponse, setErrorResponse] = useState(null)

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    postAuthetication({email: formData.name, password: formData.password }).then((data) => {
      const accessToken = data?.accessToken;
      const name = data?.displayName
      localStorage.setItem("token", `Basic ${accessToken}`);
      localStorage.setItem("displayName", name)
      setAuthToken(accessToken)
      window.location = '/'
      toast.success(`Logged in as ${formData.name}`, {
        theme: 'colored'
      });
    }).catch((error) => {
      setFormData({name:'', password: '',})
      const responseData = error?.response;
      if (responseData && responseData.status === 401) {
        setErrorResponse('Username or password incorrect');
      } else if (responseData && responseData.status === 400) {
        setErrorResponse('User not found');
      } else {
        setErrorResponse(
          `Something went wrong, contact customer support`
        );
      }
    })
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-4">
        {hasLabel && <Form.Label>Email address/UserName</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.name}
          name="name"
          onChange={handleFieldChange}
          type="name"
        />
      </Form.Group>

      <Form.Group className="mb-4">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center mb-3">
        {/* <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              Remember me
            </Form.Check.Label>
          </Form.Check>
        </Col> */}

        <Col xs="auto"></Col>

        <Col xs="auto">
          <Link
            className="fs--1 mb-0 text-white"
          >
            Forgot Password?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          className="mt-4 w-100 mb-3 btnYellow"
          disabled={!formData.name || !formData.password}
        >
          Log in
        </Button>
      </Form.Group>
      {errorResponse ? 
      <p style={{color:"#842029",fontSize: "15px", marginBottom: "0px"}}>{errorResponse}</p>
      :
      null
      }
    </Form>
  );
};

export default LoginForm;
