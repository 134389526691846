import axios from 'axios';


export const api = axios.create({
    baseURL:process.env.REACT_APP_BACKEND_URL,
    headers:{
        'content-Type':'application/json',
    }
})

export const unsecuredApi = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });
