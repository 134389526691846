import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Row,
  Col,
  Modal,
  Form,
  Spinner,
} from "react-bootstrap";
import { CgClose } from "react-icons/cg";
import { BiArrowToBottom } from "react-icons/bi";
import { AiOutlineExport } from "react-icons/ai";
import { submitCauchyJob, getCauchyStatus } from "../../Service/mapService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "../CardButton/CardButton.scss";
import { BsTelephoneMinus } from "react-icons/bs";

const DownwardContinuationModal = ({openModal, toggleModal, row}) => {
  const [formData, setFormData] = useState({
    "outputLayername": "",
    "numberOfLayers": "",
    "depthStepSize": "",
    "startingDepth": "",
    "paddingPercentage": "",
    "detrendDegree": "",
    "integrationRadius": "",
    "useExpansion": true,
    "workflowId": ""
  });
  const [workflowId, setWorkflowId] = useState(null);
  const timerRef = useRef(null)
  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();

  const clearFormData = () => {
    setFormData({
      outputLayername: "",
      numberOfLayers: "",
      depthStepSize: "",
      startingDepth: "",
      paddingPercentage: "",
      detrendDegree: "",
      integrationRadius: "",
      useExpansion: true,
      workflowId: ""
    })
  }

  const handleClose = () => {
    clearFormData()
    setValidated(false)
    toggleModal(false)
  }

  useEffect(() => {
    if (!workflowId) {
      clearInterval(timerRef);
    }
  }, [workflowId]);

  useEffect(() => {
    setFormData({...formData, workflowId: row?.id, outputLayername: row?.layerName})    
  }, [openModal]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      submitCauchyJob(formData).then((data) => {
        if (data.workflowId) {
          setWorkflowId(data.workflowId);
          timerRef.current = setInterval(() => getDatasetStatus(data.workflowId), 5000)
        }
      }).catch(error => {
        toast.error("An error occurred. Please try again later")
        toggleModal(false)
      });
    }
    setValidated(true);
  };

  const getDatasetStatus = async (id) => {
    if (id) {
      getCauchyStatus(id).then((data) => {
        if (data.workflow.status == "COMPLETED") {
          clearInterval(timerRef.current)
          toggleModal(false);
          setWorkflowId(null);
          toast.success("Dataset Processing Complete");
          navigate("/downwardcontinuationpage");
        } else if (data.workflow.status == "FAILED") {
          toast.error("Dataset Processing Failed");
          clearInterval(timerRef.current)
          setWorkflowId(null)
          toggleModal(false)
        }
      });
    }
  };

  return (
    <>
      {openModal ? (
        <div>
          <Modal
            show={openModal}
            backdrop="static"
            keyboard={false}
            style={{ zIndex: "99999" }}
            className="styleModal"
          >
            <Modal.Header>
              <Modal.Title>Downward Continuation</Modal.Title>
              <Button onClick={() => handleClose()} className="closeIcon">
                <CgClose />
              </Button>
            </Modal.Header>
            <Modal.Body>
            {workflowId ? (
                <div style={{ textAlign: "center" }}>
                  <Spinner animation="grow" />
                  <div style={{ marginTop: "1em" }}>
                    Processing data...please wait
                  </div>
                </div>
            ) : (
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                <Col md={12}>
                    <Form.Group>
                      <Form.Label>Layer Name</Form.Label>
                      <Form.Control
                        type="string"
                        name="outputLayername"
                        placeholder="Layer Name"
                        required
                        value={formData?.outputLayername}
                        onChange={(e) => {
                          setFormData({...formData, outputLayername: e.target.value});
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Layer Name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Number of Layers</Form.Label>
                      <Form.Control
                        type="number"
                        min={0}
                        name="numberOfLayers"
                        placeholder="Number of Layers"
                        required
                        value={formData?.numberOfLayers}
                        onChange={(e) => {
                          setFormData({...formData, numberOfLayers: e.target.value});
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Number of Layers.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Depth Step Size</Form.Label>
                      <Form.Control
                        type="number"
                        min={0}
                        step="0.01"
                        name="depthStepSize"
                        placeholder="Depth Step Size"
                        required
                        value={formData?.depthStepSize}
                        onChange={(e) => {
                          setFormData({...formData, depthStepSize: e.target.value});
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Depth Step Size.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Starting Depth</Form.Label>
                      <Form.Control
                        type="number"
                        min={0}
                        name="startingDepth"
                        placeholder="Starting Depth"
                        required
                        value={formData?.startingDepth}
                        onChange={(e) => {
                          setFormData({...formData, startingDepth: e.target.value});
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Starting Depth.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Integration Radius</Form.Label>
                      <Form.Control
                        type="number"
                        min={0}
                        name="integrationRadius"
                        placeholder="Integration Radius"
                        required
                        value={formData?.integrationRadius}
                        onChange={(e) => {
                          setFormData({...formData, integrationRadius: e.target.value})
                        }}
                        />
                        <Form.Control.Feedback type="invalid">
                        Please provide a valid Integration Radius.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Padding Percentage</Form.Label>
                      <Form.Control
                        type="number"
                        min={0}
                        step="0.01"
                        name="paddingPercentage"
                        placeholder="Padding Percentage"
                        required
                        value={formData?.paddingPercentage}
                        onChange={(e) => {
                          setFormData({...formData, paddingPercentage: e.target.value});
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Padding Percentage.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                  <Form.Group>
                      <Form.Label>Detrend Degree</Form.Label>
                      <Form.Control
                        type="number"
                        min={0}
                        max={2}
                        name="detrendDegree"
                        placeholder="Detrend Degree"
                        required
                        value={formData?.detrendDegree}
                        onChange={(e) => {
                          setFormData({...formData, detrendDegree: e.target.value});
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Detrend Degree.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Group>
                    <Form.Label></Form.Label>
                      <Form.Check
                        type={'checkbox'}
                        label={'Use Expansion'}
                        checked={formData.useExpansion}
                        onChange={(e) => {
                          setFormData({...formData, useExpansion: e.target.checked});
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                  </Col>
                  <Col md={4}>
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <Col md={12} style={{textAlign: 'right'}}>
                    <Button
                      variant="primary"
                      className="btnBlack m-2"
                      onClick={(e) => {handleClose()}}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      className="btnYellow"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>)}
            </Modal.Body>
          </Modal>
        </div>
      ) : null}
    </>
  );
};

export default DownwardContinuationModal;
