import React from 'react';
import LoginForm from '../../Components/LoginForm/LoginForm';

import AuthCardLayout from '../../Layouts/AuthCardLayout'

const Login = () => {
  return (
    <AuthCardLayout>
      <h3>Account Login</h3>
      <LoginForm layout="card" hasLabel />
    </AuthCardLayout>
  );
};

export default Login;
