import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CardButton from "../../Components/CardButton/CardButton";

export const DataPage = ({rowField, setRowField, downwardContinuationActivate}) => {
  return (
    <Container className="mt-4 px-4">
      <Row>
        <Col>
          <CardButton rowField={rowField} setRowField={setRowField} downwardContinuationActivate={downwardContinuationActivate} />
        </Col>
      </Row>
    </Container>
  );
};
