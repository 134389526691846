

//API End Points

import { FiMap, FiDatabase, FiUser, FiChevronsDown } from "react-icons/fi";
import { BiCube } from "react-icons/bi"
import { AccountPage } from "./Pages/AccountPage/AccountPage";
import { DataPage } from "./Pages/DataPage/DataPage";
import { ProjectPage } from "./Pages/ProjectPage/ProjectPage";
import {MapPage} from './Pages/MapPage/MapPage';
import { DownwardContinuationPage } from "./Pages/DownwardContinuationPage/DownwardContinuationPage";




export const MAPROUTES = [
    {
        path: '/',
        exact: true,
        subMenu: "Map",
        component: MapPage,
        icon: <FiMap />,
    },
    {
        path: '/projectpage',
        exact: true,
        component: ProjectPage,
        subMenu: "Project",
        icon: <BiCube />,
    },
    {
        path: '/datapage',
        exact: true,
        subMenu: "Data",
        component: DataPage,
        icon: <FiDatabase />,
    },
    {
        path: '/downwardcontinuationpage',
        exact: true,
        subMenu: "Downward Continuation",
        component: DownwardContinuationPage,
        icon: <FiChevronsDown />,
    },
    {
        path: '/accountpage',
        exact: true,
        subMenu: "Account",
        component: AccountPage,
        icon: <FiUser />,
    }
]