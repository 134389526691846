import React from "react";
import { FaImage, FaRegFileAlt} from 'react-icons/fa'
import { FiFolder } from "react-icons/fi";
import {MdDescription} from 'react-icons/md'
import './CustomNode.scss';
  
  export const TypeIcon = (props) => {
    if (props.droppable) {
      return <FiFolder className="fiFolder"/>;
    }
  
    switch (props.fileType) {
      case "image":
        return <FaImage />;
      case "csv":
        return <FaRegFileAlt />;
      case "text":
        return <MdDescription />;
      default:
        return null;
    }
  };