/* eslint-disable no-self-compare */
import { api, unsecuredApi } from '../api';
import { Buffer } from "buffer";
const SENSORE_GUI_API = '/guiapi';
export const SENSORE_API_GET_LAYER = process.env.REACT_APP_BACKEND_URL + SENSORE_GUI_API + '/getcatalogue';
export const SENSORE_API_GET_LAYERTILE = SENSORE_GUI_API + '/getlayertile';
export const SENSORE_API_GET_CATALOGUE =  SENSORE_GUI_API + '/getcatalogue';
export const SENSORE_API_GET_STYLES = SENSORE_GUI_API + '/getavailablestyles';
export const SENSORE_API_UPLOAD_DATA = SENSORE_GUI_API + '/updatestyle';
export const SENSORE_API_GET_SEARCH_AUTOCOMPLETE = SENSORE_GUI_API + '/getSearchAutocomplete';
export const GET_NAMED_MAPSHEET = SENSORE_GUI_API + '/getNamedMapsheet';
export const GET_LAYER_ATTRIBUTES = SENSORE_GUI_API + '/getLayerAttributes';
export const SENSORE_API_APPLY_QGIS_STYLE = SENSORE_GUI_API + '/applyQgisStyle';
export const SENSORE_API_EDIT_COLOR_RAMP = SENSORE_GUI_API + '/editColorRamp';
export const GET_AVAILABLE_DATASETS = SENSORE_GUI_API +'/getAvailableDataSets';
export const SENSORE_API_UPLOAD_DATASET = SENSORE_GUI_API + '/uploadBYOD';
export const SENSORE_API_POLL_BYOD_WORKFLOW = SENSORE_GUI_API + '/pollWorkflowStatus';
export const SENSORE_API_GET_BYOD_LAYERS = SENSORE_GUI_API + '/getWorkflows?type=byod&status=COMPLETED';
export const SENSORE_API_CAUCHY_SUBMIT_JOB = SENSORE_GUI_API + '/runDownwardContinuation';
export const SENSORE_API_CAUCHY_POLL_WORKFLOW = SENSORE_GUI_API + '/pollWorkflowStatus';
export const SENSORE_API_CAUCHY_GET_FILES = SENSORE_GUI_API + '/getWorkflows?type=intrepid&status=COMPLETED';
export const GET_BYOD_FILE_TYPES = SENSORE_GUI_API + '/getByodFileTypes';
export const AVAILABLE_PRINT_LAYOUTS = SENSORE_GUI_API + '/getAvailablePrintLayouts';
export const GET_PRINT = SENSORE_GUI_API + '/getPrint'
export const POST_AUTHENTICATION = SENSORE_GUI_API + '/login'

var bearerToken = `${localStorage.getItem('token')}`

//authetication unsecured api 
export const postAuthetication = async (credentials) => {
   const token = `${credentials.email}:${credentials.password}`;
    var basicAuth = 'Basic ' + Buffer.from(token).toString('base64');
    const response = await unsecuredApi.post(POST_AUTHENTICATION, credentials,{
        headers: { 'Authorization': basicAuth }
    });
    return response.data;
}


//get layers api
export const getLayersApi = async () => {
    const response = await api.get(SENSORE_API_GET_CATALOGUE, {
        headers: { 'Authorization': bearerToken }
    });
    return response.data;
};


export const getLayerMap = async() => {
    const response = api.getUri({
        url: `${SENSORE_API_GET_LAYERTILE}`,
        headers: { 'Authorization': bearerToken }
    })
    return response;
}


export const getAuthorizationToken = async () => {
    return bearerToken;
};

api.interceptors.response.use(
    function (response) {
        if (response.data) {
            // return success
            if (response.status === 200 || response.status === 201) {
                return response;
            }
            // reject errors & warnings
            return Promise.reject(response);
        }

        // default fallback
        return Promise.reject(response);
    },
    function (error) {
        // if the server throws an error (404, 500 etc.)
        return Promise.reject(error);
    }
);

//get available styles

export const getAvailableStyles = async(type) => {
    const response = await api.get(SENSORE_API_GET_STYLES.concat(`?type=${type}`), {
        headers: { 'Authorization': bearerToken }
    });
    return response.data;
}

export const getNamedMapsheet = async(searchName) => {
    const {data} = await api.get(GET_NAMED_MAPSHEET.concat(`?searchName=${searchName}`), {
        headers: { 'Authorization': bearerToken }
    });
    return data;
}

export const uploadAvailableStyles = async(getUploadDetails) => {
   const response =  await api.post(SENSORE_API_UPLOAD_DATA, getUploadDetails, {
    headers: { 'Authorization': bearerToken }
   })
   return response.data;
}

export const applyQgisStyle = async(layerId, styleId) => {
   const response =  await api.post(SENSORE_API_APPLY_QGIS_STYLE.concat(`?layerId=${layerId}&styleId=${styleId}`), 'Hello', {
    headers: { 'Authorization': bearerToken }
   })
   return response.data;
}

export const editColorRamp = async(payload) => {
    const response =  await api.post(SENSORE_API_EDIT_COLOR_RAMP, payload, {
     headers: { 'Authorization': bearerToken }
    })
    return response.data;
 }

export const getSearchAutoComplete = async(dataCatalogueAttribute_id, searchValue) => {
    const response = await api.get(SENSORE_API_GET_SEARCH_AUTOCOMPLETE + `?dataCatalogueAttributeId=${dataCatalogueAttribute_id}&searchValue=${searchValue}`,{
        headers:{ 'Authorization': bearerToken }
    })
    return response.data
}

export const getLayerAttributes = async(layerId, lat, lon, zoomLevel) => {
    const {data} = await api.get(GET_LAYER_ATTRIBUTES.concat(`?layerId=${layerId}&lat=${lat}&lon=${lon}&zoomLevel=${zoomLevel}`), {
        headers: { 'Authorization': bearerToken }
    });
    return data;
}
export const getAvailableDataSets = async(minA, minB, maxA, maxB, CRS) => {
    const response = await api.get(GET_AVAILABLE_DATASETS + `?dataGeomMinA=${minA}&dataGeomMinB=${minB}&dataGeomMaxA=${maxA}&dataGeomMaxB=${maxB}&crs=4326`,{
        headers:{ 'Authorization': bearerToken }
    })
    return response.data
}

// BYOD APIs
export const uploadDataset = async(file, name, type) => {
    let formData = new FormData();
    Array.from(file).forEach(fl => {
        formData.append('file', fl);
    });
    formData.append('layerName', name);
    formData.append('fileType', type);
    const response =  await api.post(SENSORE_API_UPLOAD_DATASET, formData, {
     headers: { 'Authorization': bearerToken, 'Content-Type': 'multipart/form-data' }
    })
    return response.data;
}

export const getDatasetUploadStatus = async(workflowId) => {
    const response =  await api.get(SENSORE_API_POLL_BYOD_WORKFLOW + `?id=${workflowId}`, {
     headers: { 'Authorization': bearerToken }
    })
    return response.data;
}

export const getBYODLayers = async() => {
    const response =  await api.get(SENSORE_API_GET_BYOD_LAYERS, {
     headers: { 'Authorization': bearerToken }
    })
    return response.data;
}

// Cauchy APIs
export const submitCauchyJob = async(formData) => {
    const response =  await api.post(SENSORE_API_CAUCHY_SUBMIT_JOB, formData, {
     headers: { 'Authorization': bearerToken }
    })
    return response.data;
}

export const getCauchyStatus = async(workflowId) => {
    const response =  await api.get(SENSORE_API_CAUCHY_POLL_WORKFLOW + `?id=${workflowId}`, {
     headers: { 'Authorization': bearerToken }
    })
    return response.data;
}

export const getCauchyData = async() => {
    const response =  await api.get(SENSORE_API_CAUCHY_GET_FILES, {
     headers: { 'Authorization': bearerToken }
    })
    return response.data;
}

export const getByodFileTypes = async() => {
    const response = await api.get(GET_BYOD_FILE_TYPES, {
        headers: { 'Authorization': bearerToken }
    });
    return response.data;
}

export const getAvailablePrintLayouts = async() => {
    const response = await api.get(AVAILABLE_PRINT_LAYOUTS, {
        headers: { 'Authorization': bearerToken }
    });
    return response.data
}

export const getPrint = async({layers, styles, opacities, geomMinA, geomMinB, geomMaxA, geomMaxB, template, format}) => {
    const response = await api.get(GET_PRINT + `?crs=EPSG:4326&layers=${layers}&styles=${styles? styles: ''}&opacities=${opacities}&geomMinA=${geomMinA}&geomMinB=${geomMinB}&geomMaxA=${geomMaxA}&geomMaxB=${geomMaxB}&template=${template}&format=${format}`,{
        responseType: 'blob',
        headers: { 'Authorization': bearerToken }
    });
    return response.data
}
export const removeObjectNull = (obj) => {
    return Object.entries(obj).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    );
  };

  export const checkIfValidUUID = (str) => {
    // Regular expression to check if string is a valid UUID
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    return regexExp.test(str);
  }

  export const isFloat = (str) => {
    const regexExp = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
    return regexExp.test(str);
  }
  
  export const uuidv4 = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }
  