import React,{useState} from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import './AccountPage.scss';

export const AccountSplitPane = () => {
  const [userName,] = useState(localStorage.getItem("displayName"))
  const windowGlobal = typeof window !== 'undefined' && window
  const localStorageVar = windowGlobal ? localStorage : null

  const logoutHandler = () => {
    window.location.href = '/loginpage'
    localStorageVar.clear()
  }
  return (
    <>
      <Container>
        <Row>
          <Col  className="accountHeader">
            Welcome {userName}
          </Col>
        </Row>
        <Row>
          <Col>
            <Button className="btnYellow" onClick={logoutHandler}>Logout</Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};
