'use strict';

import L from "leaflet";
import Browser from "leaflet";
import { extend, setOptions, TileLayer, Bounds } from "leaflet";
import * as Util from "leaflet/src/core/Util";
import * as DomUtil from "leaflet/src/dom/DomUtil";


/**
 * Custom function for the TileLayer extension to fetch the tile images with Authorization header.
 * Reference: https://github.com/ticinum-aerospace/leaflet-wms-header
 * @param {*} url 
 * @param {*} callback 
 * @param {*} headers 
 * @param {*} abort 
 * @param {*} requests 
 */
async function fetchImage(url, callback, headers, abort, requests) {
    let _headers = {};
    if (headers) {
      headers.forEach(h => {
        _headers[h.header] = h.value;
      });
    }
    const controller = new AbortController();
    const signal = controller.signal;
    if (abort) {
      abort.subscribe(() => {
        controller.abort();
      });
    }
  
    const request = {
      url,
      controller
    };
    requests.push(request);
  
    fetch(url, {
      method: "GET",
      headers: _headers,
      mode: "cors",
      signal: signal
    }).then(async f => {
      const blob = await f.blob();
      callback(blob);
    });
}

L.TileLayer.SensoreTileLayer = L.TileLayer.extend({
    initialize: function (url, authToken, reference, pyramidLevels, options, abort) {
        this._url = url;
        var wmsParams = extend({});
        options = setOptions(this, options);
        var realRetina = options.detectRetina && Browser.retina ? 2 : 1;
        var tileSize = this.getTileSize();
        wmsParams.width = tileSize.x * realRetina;
        wmsParams.height = tileSize.y * realRetina;
        wmsParams.reference = reference;
        wmsParams.pyramidLevels = pyramidLevels;
        this.wmsParams = wmsParams;
        this.headers = [{ header: 'Authorization', value: authToken }];
        this.abort = abort;
        this.requests = [];
        this.authToken = authToken;
        this.on('tileunload', this._onTileRemove);
    },

    onAdd: function (map) {
        this.map = map;
        this._crs = this.options.crs || map.options.crs;
        TileLayer.prototype.onAdd.call(this, map);
    },

    // @method getLayerReference(reference: String, pyramidLevels: String, selectedResolutionIndex: int): this
    // If layer has pyramid levels then the the layer reference is derived from either the zoom level (ie. automatic)
    // or the selected resolution. Otherwise, the reference is returned as is.
    getLayerReference: function (reference, pyramidLevels, selectedResolutionIndex) {
        if(pyramidLevels) {
        var resolutionIndex = selectedResolutionIndex;
        if(selectedResolutionIndex === undefined) { // automatically set the pyramid
            var zoomLevel = this.map.getZoom();
            var levels = pyramidLevels.split(',');
            var lowResolution = levels[levels.length - 1].trim();
            if(pyramidLevels.length >= 2) { // If a mid level resolution defined
            var midResolution = levels[0].trim(); // TODO: devise the logic to map the mid resolutions to the zoom level
            if(zoomLevel <= 5 ) { // Low resolution
                resolutionIndex = lowResolution;  
            } else if (midResolution && zoomLevel <= 7 ) {
                resolutionIndex = midResolution;  
            }
            } else if(zoomLevel <= 7 ) { // Otherwise set the low resolution
            resolutionIndex = lowResolution;  
            }

        } else if(selectedResolutionIndex === 0) { // Highest resolution selected
            resolutionIndex = undefined;
        }

        if(resolutionIndex) {
            return reference.replace('<pyramid_level>', resolutionIndex);  
        }            
        return reference.replace('o_<pyramid_level>_', '');  // return the highest resolution
        }
        return reference;
    },

    getTileUrl: function (coords) {
        var url = TileLayer.prototype.getTileUrl.call(this, coords);
        var tileBounds = this._tileCoordsToNwSe(coords),
        crs = this._crs,
        bounds = new Bounds(
            crs.project(tileBounds[0]),
            crs.project(tileBounds[1])
        ),
        min = bounds.min,
        max = bounds.max;

        // Handling the pyramid level:
        var selectedResolutionIndex; //TODO: Fetch from layer's resolution option
        var layerReference = this.getLayerReference(this.wmsParams.reference, this.wmsParams.pyramidLevels, selectedResolutionIndex);

        var tileUrl =
        url +
        "&layerReference=" + layerReference +
        "&width=" +
        this.wmsParams.width +
        "&height=" +
        this.wmsParams.height +
        "&minA=" + min.y +
        "&minB=" + min.x +
        "&maxA=" + max.y +
        "&maxB=" + max.x;
        return tileUrl;
    },
    createTile(coords, done) {
        const url = this.getTileUrl(coords);
        const img = document.createElement("img");

        // DomEvent.on(img, 'load', Util.bind(this._tileOnLoad, this, done, img));
		// DomEvent.on(img, 'error', Util.bind(this._tileOnError, this, done, img));

        img.setAttribute("role", "presentation");
        img.setAttribute("data-url", url);

        fetchImage(
            url,
            resp => {
                const reader = new FileReader();
                reader.onload = () => {
                    img.src = reader.result;
                };
                reader.readAsDataURL(resp);
                done(null, img);
            },
            this.headers,
            this.abort,
            this.requests
        );
        return img;
    },
    _abortLoading: function() {
        for (const i in this._tiles) {
        if (this._tiles[i].coords.z !== this._tileZoom) {
            const tile = this._tiles[i].el;

            tile.onload = Util.falseFn;
            tile.onerror = Util.falseFn;

            const url = tile.getAttribute("data-url");
            const j = this.requests.findIndex(r => r && r.url === url);
            if (j >= 0) {
            this.requests[j].controller.abort();

            tile.src = Util.emptyImageUrl;
            DomUtil.remove(tile);
            delete this._tiles[i];
            delete this.requests[j];
            }
        }
        }
    }
});

L.TileLayer.sensoreTileLayer = function (url, authToken, reference, pyramidLevels, options, abort) {
    return new L.TileLayer.SensoreTileLayer(url, authToken, reference, pyramidLevels, options, abort);
};