import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { CgPentagonRight } from "react-icons/cg";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BsChevronDoubleDown } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import "./DataPage.scss";

export const DataSplitPane = ({rowField, setDownwardContinuationActivate}) => {

  const navigate = useNavigate();

  const toggleDownardContinuation = () => {
    setDownwardContinuationActivate(true);
    setTimeout(() => setDownwardContinuationActivate(false), 2000);
  }

  return (
    <>
      <Container>
        <Row>
          <Col className="dataHeader">
            <CgPentagonRight />
            <p>Actions</p>
          </Col>
          {Object.keys(rowField).length === 0 ? (
            <Row>
              <Col>
                <div className="info-box">
                  <Row>
                    <Col xs={2}><AiOutlineInfoCircle size={24} style={{color: '#00bdbd'}}/></Col>
                    <Col xs={10}>Select one or more items to view relevant actions</Col>
                  </Row>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col className="rowButton d-grid gap-2">
                <Button style={{fontWeight: 'bold'}} onClick={() => navigate("/", { state: { addLayerToMap: true } })}>View on Map</Button>
                {rowField?.fileType?.name == 'tif' || rowField?.fileType?.name == 'ers' ? 
                <Button style={{fontWeight: 'bold'}} onClick={toggleDownardContinuation} ><BsChevronDoubleDown size={18} style={{color: '#ffba03'}}/> Downward Continuation</Button> : null }
              </Col>
            </Row>
          )}
        </Row>
      </Container>
    </>
  );
};
