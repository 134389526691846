import React from 'react';
import './NavbarVertical.scss';
import {
  ProSidebar,
  SidebarContent,
  Menu,
  MenuItem
} from "react-pro-sidebar";
import { Row, Col } from 'react-bootstrap';
import "react-pro-sidebar/dist/css/styles.css";
import { Link, useLocation } from 'react-router-dom';
import { MAPROUTES } from '../../constants';
import { DataSplitPane } from '../../Pages/DataPage/DataSplitPane';
import { MapSplitPane } from '../../Pages/MapPage/MapSplitPane';
import { ProjectSplitPane } from '../../Pages/ProjectPage/ProjectSplitPane';
import { AccountSplitPane } from '../../Pages/AccountPage/AccountSplitPane';
import { DownwardContinuationSplitPane } from '../../Pages/DownwardContinuationPage/DownwardContinuationSplitPane';



export const NavbarMapVertical = ({ 
  selectedNodes,
  setSelectedNodes,
  sliderRange,
  setSliderRange,
  layerStyleMap,
  layerFilterMap,
  layerBounds,
  removelayerBounds,
  toastZoomLevel,
  rowField,
  setDownwardContinuationActivate
}) => {


  const location = useLocation();

  return (
    <div id="header">
      <ProSidebar>
        <SidebarContent>
          <Row>
            <Col md={2} style={{ padding: "0px" }}>
              <Menu iconShape="square">
                {MAPROUTES.map((route, index) => {
                  return (
                    <MenuItem key={index} icon={route?.icon} active={location.pathname == route.path}>
                      <Link to={route.path} />
                    </MenuItem>
                  )
                })}
              </Menu>
            </Col>
            <Col md={10} style={{ padding: "0px" }}>
              {
                location.pathname === '/' ?
                  <MapSplitPane
                    setSelectedNodes={setSelectedNodes}
                    selectedNodes={selectedNodes}
                    sliderRange={sliderRange}
                    setSliderRange={setSliderRange}
                    layerStyleMap={layerStyleMap}
                    layerFilterMap={layerFilterMap}
                    layerBounds={layerBounds}
                    removelayerBounds={removelayerBounds}
                    toastZoomLevel = {toastZoomLevel}
                    rowField={rowField}
                    />
                  : location.pathname === '/projectpage' ?
                    <ProjectSplitPane />
                    : location.pathname === '/datapage' ?
                      <DataSplitPane rowField={rowField} setDownwardContinuationActivate={setDownwardContinuationActivate} /> :
                      location.pathname === '/accountpage' ?
                        <AccountSplitPane />
                        : location.pathname === '/downwardcontinuationpage' ?
                          <DownwardContinuationSplitPane rowField={rowField} /> :
                            null
              }
            </Col>
          </Row>
        </SidebarContent>
      </ProSidebar>
    </div>
  )
}
