import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import "../Pages/LoginPage/LoginPage.scss";
import SensoreLogo from '../assets/images/Sensore_Logo_LoginVertical.png';

const AuthCardLayout = ({ leftSideContent, children }) => {
  return (
    <Row className="g-0 min-vh-100 cardCenter">
      <Col lg={8} xxl={5} className="py-3 position-relative">
        <Card className="overflow-hidden z-index-1">
          <Card.Body className="p-0">
            <Row className="h-100 g-0">
              <Col md={5} className="text-center cardLeft">
                <div className="position-relative p-4 pt-md-5 pb-md-7">
                  <div className="z-index-1 position-relative">
                    <img src={SensoreLogo} alt="Sensor_logo" />
                    <p className="opacity-75 text-black pt-4">
                      SensOre combines proprietary technology,
                      big data and technical excellence to 
                      advance exploration success.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={7} className="cardRight">
                <div className="p-4 p-md-5 flex-grow-1 h-100">{children}</div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default AuthCardLayout;
