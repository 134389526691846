import React, { useEffect, useState, useRef } from "react";
import { BiCaretDown, BiCaretUp } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import {CgClose} from 'react-icons/cg'
import { TypeIcon } from "./TypeIcon";
import { Button, Overlay, Tooltip, Modal, Form } from "react-bootstrap";
import InputNumber from "rc-input-number";
import "rc-slider/assets/index.css";
import "rc-input-number/assets/index.css";
import "./CustomNode.scss";
import CustomSelect from "./CustomSelect";
import {
  uploadAvailableStyles,
  removeObjectNull,
  getAvailableStyles,
  applyQgisStyle,
  editColorRamp
} from "../../Service/mapService";
import CreateSearchFilter from "../CreateSearchFilter/CreateSearchFilter";
import Select from "react-select";
import { toast } from "react-toastify";

export const CustomNode = (props) => {
  let selectedNode = props.selectedNodes?.find((n) => n?.id === props.node?.id);
  let GreenLayer =  props.highLightText?.find((n) => n?.id === props.node?.id);
  const NO_STYLE_OPTION = {id:"00000000-0000-0000-0000-000000000000", value:"noStyle", label:"No Style"}
  const openStreetMapId = 'addddddb-b8ce-463d-9ecf-a4d719c11900'
  const satelliteImageryId = "addddddb-b8ce-463d-9ecf-a4d719c16800"
  const [show, setShow] = useState(false);
  const [availableStyles, setAvailableStyles] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openCreateFilterModal, setOpenCreateFilterModal] = useState(false);
  const [xmlContent, setXMLContent] = useState(null);
  const [xmlStyleName, setXMLStyleName] = useState(null);
  const [getStyles, setStyles] = useState([]);
  const [apiError, setApiError] = useState("");
  const [openEditColorRamp, setOpenEditColorRamp] = useState(false);
  const [templateRasterStyle, setTemplateRasterStyle] = useState(null);
  const [minimumValue, setMinimumValue] = useState(null);
  const [maximumValue, setMaximumValue] = useState(null);
  const [colorRampMode, setColorRampMode] = useState(null);
  const defaultErrorMessage = "An unexpected error occurred.";
  const { droppable, data } = props.node;
  const target = useRef(null);
  const indent = props.depth * 15;
  const removeActiveLayer = props.removeActiveLayer;
  const nodeId = props.node.id;
  let activeLayer = props.activeLayer;

  const STYLE_TYPE_RASTER_COLOR_RAMP = "RasterColorRamp";
  const STYLE_TYPE_SLD = "SLD";

  const COLOR_RAMP_MODES = [
    {value: 'Quantile', label: 'Quantile'},
    {value: 'Continuous', label: 'Continuous'},
    {value: 'Equal Interval', label: 'Equal Interval'},
  ];

  useEffect(() => {

    if(show && selectedNode?.styleType){
      getAvailableStyles(selectedNode.styleType)
      .then((data) => {
        setStyles(data?.styles);
      })
      .catch((error) => {
        setApiError(
          error &&
            error.response &&
            error.response.data["header"] &&
            error.response.data["header"]["errorText"]
            ? defaultErrorMessage +
                " : " +
                error.response.data["header"]["errorText"]
            : defaultErrorMessage
        );
      });
    }
  },[props.setStyles, show])

  const handleToggle = (e) => {
    e.stopPropagation();
    props.onToggle(props.node.id);
  };

  const handleSelect = () => props.onSelect(props.node);
  const handleClose = (e) => {
    e.stopPropagation();
    setOpenModal(false)
  };

  const handleCloseEditColorRamp = (e) => {
    setOpenEditColorRamp(false);
  };

  const handleCreateFilterModalClose = (e) => {
    e.stopPropagation();
    setOpenCreateFilterModal(false);
  }

  const handleChangeSlider = (value) => {
    let selectedSliderRange = props.selectedNodes.map((data) => {
      if (selectedNode.id === data.id) {
        data.sliderRange = value;
        return data;
      }
      return data;
    });
    props.setSelectedNodes(selectedSliderRange);
  };

  const handleChange = (value) => {
    if(selectedNode.styleType === STYLE_TYPE_RASTER_COLOR_RAMP && value.value !== NO_STYLE_OPTION.value) {
      applyQgisStyle(selectedNode.id, value.id).then((data) => {
        updateSelectedStyle(value);
      });
    } else {
      updateSelectedStyle(value);
    }

  };

  const updateSelectedStyle = (value) => {
    let currentSelectedStyleID = props.selectedNodes.map((data) => {
      if (selectedNode.id === data.id) {
        if(value && value.value !== NO_STYLE_OPTION.value) {
          props.layerStyleMap[data.id] = value.id;
        } else {
          delete props.layerStyleMap[data.id];
        }
      }
      return data;
    });
    props.setSelectedNodes(currentSelectedStyleID);
  }

  const submitCreateFilters = (uuid, filterName) => {
    setOpenCreateFilterModal(false);

    if(uuid === props.node.id) {
      props.node.text = filterName;
      // Force changes to selectNodes to update the map layers with the saved filters.
      let currentSelectedStyleID = props.selectedNodes.map((data) => {
        return data;
      });
      props.setSelectedNodes(currentSelectedStyleID);
    } else {
      props.onSelect({...props.node, id: uuid, text: filterName , isSelected: false, isFilter: true})
    }
  }

  let selectDefaultValue = props.selectedNodes?.find(
    (data) => data?.id === props.node?.id
  );

  let selectedStyleId = (selectDefaultValue)? props.layerStyleMap[selectDefaultValue.id]:null;
  
  useEffect(() => {
    if(selectedNode) {
      if (selectedNode.isStyleable === 0) {
        setAvailableStyles([
          { id: "1", value: "defaultValue", label: "DefaultStyle" },
        ]);
      } else {
        let tableList = getStyles.map((data) => {
          return (
            { id: data.id, value: data.name, label: data.name }
          )
        });
        tableList.push(NO_STYLE_OPTION)
        setAvailableStyles(tableList);
      }
    }
  }, [getStyles, selectedNode]);

  const handleUpload = async (e) => {
    let file = e.target.files[0];
    const TEXT_XML = await file.text();
    setXMLContent(TEXT_XML);
  };

  const handleSubmit = async (e) => {
    e.stopPropagation()
    let xmlBody = {
      name: xmlStyleName,
      type: selectedNode.styleType,
      description: "XML style",
      xml: `${xmlContent}`,
    };
    let xmlContentBody = removeObjectNull(xmlBody);
    uploadAvailableStyles(xmlContentBody).then((data) => {
      setOpenModal(false);
      appendToAvailableStyles(data.style);
    });
  };

  const appendToAvailableStyles =(newStyle) => {
    let cpy_getStyle = [...getStyles];
    cpy_getStyle.push(newStyle);
    props.setStyles(cpy_getStyle);
  }

  const handleClick = (e) => {
   setShow({show:false})
  }

  const handleEditColorRampSubmit = (e) => {
    e.stopPropagation();

    let payloadBody = {
      styleId: templateRasterStyle.id,
      styleName: xmlStyleName,
      minValue: minimumValue,
      maxValue: maximumValue,
      colorRampMode: colorRampMode.value
    }

    editColorRamp(payloadBody).then((response) => {
      setOpenEditColorRamp(false);

      appendToAvailableStyles(response.style);
      setTemplateRasterStyle(null);
      setXMLStyleName(null);
      setColorRampMode(null);
      setMinimumValue(null);
      setMaximumValue(null);
    });
  }
  //display toast
  useEffect(() => {
    const customId = "custom-id-yes";
      if (selectedNode?.minZoomLevel && props.toastZoomLevel < 11) {
          toast.warn(
            `${selectedNode.text} layer is only visible at zoom level 11 or higher`,
            {
              toastId: customId,
            }
          );
        }
  }, []);



  return (
    <>
      <div className={`tree-node`}  style={(props.node.id === openStreetMapId || props.node.id === satelliteImageryId) ? {paddingInlineStart:"17px"}:{paddingInlineStart: indent}}>
        <div className="rightIcon" style={{ width: "100%" }}>
          {props.node.droppable && (
            <div onClick={handleToggle} className="arrowRight">
              {props.isOpen ? (
                <BiCaretUp className="arrowDown" />
              ) : (
                <BiCaretDown className="arrowDown" />
              )}
            </div>
          )}

          {props.node.minZoomLevel ? (
            <>
              <div className="tooltipDisplay">
                <input
                  type="checkbox"
                  color="primary"
                  checked={props.isSelected}
                  onClick={handleSelect}
                  onChange={() => {}}
                />
                <div>
                  <TypeIcon droppable={droppable} fileType={data?.fileType} />
                </div>
                <div className="labelGridItem">
                  <p style={props.node.id === GreenLayer?.id ? {color:'#00ffff'}:{color:'#ffffff'}}>{props.node.text}</p>
                </div>
                <span className="tooltiptext">
                  Visible at or above zoom level {props.node.minZoomLevel}
                </span>
              </div>
            </>
          ) : (
            <>
              <input
                type="checkbox"
                color="primary"
                checked={props.isSelected}
                onClick={handleSelect}
                onChange={() => {}}
              />
              <div>
                <TypeIcon droppable={droppable} fileType={data?.fileType} />
              </div>
              <div className="labelGridItem">
                {props.node.isFilter ?
                  (<p style={{fontStyle:'italic'}}>{props.node.text}</p>):
                  (<p style={props.node.id === GreenLayer?.id ? {color:'#00ffff'}:{color:'#ffffff'}}>{props.node.text}</p>)
                }
              </div>
            </>
          )}

          {props.node.droppable === false  ? (
            <div className="overlaySettings">
              <Button
                ref={target}
                onClick={handleClick}
                style={
                  show
                    ? { backgroundColor: "black", border: "transparent" }
                    : { backgroundColor: "transparent", border: "transparent" }
                }
              >
                <FiSettings />
              </Button>
              <Overlay target={target.current} show={show} onHide={() => setShow(!show)} placement="right" rootClose>
                {(overlayProps) => (
                  <Tooltip
                    id="overlay-example"
                    {...overlayProps}
                    className={
                      props.node.id === openStreetMapId || props.node.id === satelliteImageryId ? 'stylefliterforBaseMap':
                      selectedNode?.filterAttributes ? 'stylingFilter':
                      selectedNode?.isStyleable === 0
                        ? "Disabledstyling"
                        : "stylingTooltip"
                    }
                  >
                    {props.node.id !== openStreetMapId && props.node.id !== satelliteImageryId ?
                    <>
                    <div className="d-flex customSelectStyle" style={{ marginBottom: "10px" }}>
                      <div>Style</div>
                      <CustomSelect
                        options={availableStyles}
                        defaultValue={
                          selectedNode?.isStyleable === 0
                            ? {
                                id: "1",
                                value: "defaultValue",
                                label: "DefaultStyle",
                              }
                            : (selectedStyleId)?availableStyles.find((data) => data.id === selectedStyleId):null
                        }
                        changeOptionsData={() => setOpenModal(true)}
                        onChange={(value) => handleChange(value)}
                        type={selectedNode?.styleType}
                        showEditColorRamp={() => setOpenEditColorRamp(true)}
                        isDisabled={selectedNode?.isStyleable === 0 ? true : false}
                      />
                    </div>
                    {props.node.filterAttributes && (props.node.filterAttributes.length > 0) 
                        && (props.node.isFilter || !props.node.isInActiveLayers) 
                        && (
                      <div className="d-flex" style={{ marginBottom: "10px"}}>
                        <div>Search Filter</div>
                          <Button
                            onClick={() => setOpenCreateFilterModal(true)}
                            variant="primary"
                            size="sm"
                            style={{width:"140px"}}
                          >
                            {(props.node.isInActiveLayers === true)?'Edit':'Create'}  Filter
                          </Button>
                      </div>
                    )}
                    </>
                    :null}
                    <div className="d-flex" style={{ marginBottom: "10px" }}>
                      <div>Opacity</div>
                      <div className="react-numeric-input">
                        <InputNumber
                          defaultValue={
                            selectDefaultValue?.sliderRange
                              ? selectDefaultValue.sliderRange
                              : 100
                          }
                          min={0}
                          max={100}
                          step={10}
                          onChange={(value) => handleChangeSlider(value)}
                        />
                      </div>
                    </div>
                    {activeLayer && props.node.id !== openStreetMapId && props.node.id !== satelliteImageryId &&
                    <div className="d-flex" style={{ marginBottom: "10px" }}>
                      <Button
                        onClick={() => removeActiveLayer(nodeId)}
                        variant="primary"
                        size="sm"
                      >
                        Remove from Active Layers
                      </Button>
                    </div>}
                  </Tooltip>
                )}
              </Overlay>
            </div>
          ) : null}
          {openCreateFilterModal && (
            <div>
            <Modal
              show={openCreateFilterModal}
              backdrop="static"
              keyboard={false}
              style={{ zIndex: "99999" }}
              className="filterModal"
            >
              <Modal.Header
              >
                <Modal.Title>{!props.node.isInActiveLayers?'Create':'Update'} Search Filter</Modal.Title>
                <Button onClick={handleCreateFilterModalClose} className="closeIcon">
                    <CgClose />
                  </Button>
              </Modal.Header>
              <Modal.Body style={{ backgroundColor: "#331730" }}>
                <CreateSearchFilter
                  node={props.node}
                  isNewSearchFilter={!props.node.isInActiveLayers}
                  layerFilterMap={props.layerFilterMap}
                  filterAttributes={props.node.filterAttributes}
                  setOpenCreateFilterModal={setOpenCreateFilterModal}
                  submitCreateFilters={submitCreateFilters}
                />
              </Modal.Body>
            </Modal>
          </div>
          )}
          {openModal ? (
            <div>
              <Modal
                show={openModal}
                backdrop="static"
                keyboard={false}
                style={{ zIndex: "99999" }}
                className="styleModal"
              >
                <Modal.Header
                  style={{ backgroundColor: "#331730" }}
                >
                  <Modal.Title>Upload style</Modal.Title>
                  <Button onClick={handleClose} className="closeIcon">
                    <CgClose />
                  </Button>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#331730" }}>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Style Name</Form.Label>
                      <Form.Control
                        type="string"
                        placeholder="Enter Style Name"
                        style={{ backgroundColor: "#442040" }}
                        required
                        value={xmlStyleName || ""}
                        onChange={(e) => {
                          setXMLStyleName(e.target.value);
                        }}
                        onClick={(e) => {e.stopPropagation()}}
                      />
                      <Form.Label>Upload styles</Form.Label>
                      <Form.Control
                        type="file"
                        name="fileName"
                        accept= {(selectedNode.styleType === STYLE_TYPE_SLD)? ".xml":".qml"}
                        onChange={handleUpload}
                        onClick={(e) => {e.stopPropagation()}}
                        required
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: "#331730" }}>
                  <Button variant="primary" onClick={handleSubmit}>
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          ) : null}
          {openEditColorRamp ? (
            <div>
              <Modal
                show={openEditColorRamp}
                backdrop="static"
                keyboard={false}
                style={{ zIndex: "99999" }}
                className="styleModal"
              >
                <Modal.Header
                  style={{ backgroundColor: "#331730" }}
                >
                  <Modal.Title>Edit Color Ramp</Modal.Title>
                  <Button onClick={handleCloseEditColorRamp} className="closeIcon">
                    <CgClose />
                  </Button>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#331730" }}>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Template Style</Form.Label>
                      <Select
                        placeholder = "Select style"
                        style={{ backgroundColor: "#442040" }}
                        options={availableStyles.filter(style => style.id !== NO_STYLE_OPTION.id)}
                        value={templateRasterStyle}
                        onChange={value => setTemplateRasterStyle(value)}
                      />
                      <Form.Label>Style Name</Form.Label>
                      <Form.Control
                        type="string"
                        placeholder="New Style Name"
                        style={{ backgroundColor: "#442040" }}
                        required
                        value={xmlStyleName || ""}
                        onChange={(e) => {
                          setXMLStyleName(e.target.value);
                        }}
                        onClick={(e) => {e.stopPropagation()}}
                      />
                      <Form.Label>Color Ramp Mode</Form.Label>
                      <Select
                        placeholder = "Select Color Ramp Mode"
                        style={{ backgroundColor: "#442040" }}
                        options={COLOR_RAMP_MODES}
                        value={colorRampMode}
                        onChange={value => setColorRampMode(value)}
                      />
                      <Form.Label>Minimum value</Form.Label>
                      <Form.Control
                        type="string"
                        placeholder="Minimum data value"
                        style={{ backgroundColor: "#442040" }}
                        required
                        value={minimumValue || ""}
                        onChange={(e) => {
                          setMinimumValue(e.target.value);
                        }}
                        onClick={(e) => {e.stopPropagation()}}
                      />
                      <Form.Label>Maximum value</Form.Label>
                      <Form.Control
                        type="string"
                        placeholder="Maximum data value"
                        style={{ backgroundColor: "#442040" }}
                        required
                        value={maximumValue || ""}
                        onChange={(e) => {
                          setMaximumValue(e.target.value);
                        }}
                        onClick={(e) => {e.stopPropagation()}}
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: "#331730" }}>
                  <Button variant="primary" onClick={handleEditColorRampSubmit}>
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
