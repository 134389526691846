import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layouts from './Layouts/Layouts'
import './App.scss';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className='content'>
      <ToastContainer theme="colored" icon={false} limit={1} />
      <Router>
        <Layouts />
      </Router>
    </div>

  );
}

export default App;
