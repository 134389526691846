import React, { useState, useEffect } from "react";
import "./MapPage.scss";
import { DndProvider } from "react-dnd";
import { CustomNode } from "../../Components/CustomNode/CustomNode";
import {
  Tree,
  MultiBackend,
  getBackendOptions,
} from "@minoru/react-dnd-treeview";
import { getLayersApi, getAvailableDataSets } from "../../Service/mapService";
import { useLocation } from "react-router-dom";

export const MapSplitPane = ({
  setSelectedNodes,
  selectedNodes,
  layerStyleMap,
  layerFilterMap,
  layerBounds,
  removelayerBounds, 
  toastZoomLevel,
  rowField
}) => {
  const [CatalogueData, setCataloguedata] = useState([]);
  const [apiError, setApiError] = useState("");
  const [dndData, setDndData] = useState([]);
  const [expandLayers, setExpandLayers] = useState(null)
  const [highLightText, setHighLightText] = useState(null);
  const [OpenLayer, setOpenLayer] = useState(null)
  const [dndActiveLayers, setDndActiveLayers] = useState([{
    "parent": 0,
    "droppable": true,
    "id": "edd6122b-b8ce-463d-9ecf-a4d719c02fc0",
    "description": "Active Layers",
    "active": 1,
    "icon": "layers.png",
    "text": "Active Layers"
  }]);
  const [enableDnd, setEnableDnd] = useState(true);
  
  const defaultErrorMessage = "An unexpected error occurred.";

  const {state} = useLocation();

  useEffect(() => {
    fetchCatalogue();
    setEnableDnd((prevState) => !prevState);
  }, []);

  useEffect(() => {
    populateDndData();
  }, [CatalogueData])

  const fetchCatalogue = () => {
    getLayersApi()
      .then((data) => {
        let tempCatalogueData = data.catalogueHeadings;
        let openStreetMap = {
          "parent": 0,
          "droppable": false,
          "id": "addddddb-b8ce-463d-9ecf-a4d719c11900",
          "description": "Open street Map",
          "active": 1,
          "icon": "layers.png",
          "name": "Open street Map",
          "sliderRange":100,
        }
        let satelliteImagery = {
          "parent": 0,
          "droppable": false,
          "id": "addddddb-b8ce-463d-9ecf-a4d719c16800",
          "description": "satellite imagery",
          "active": 1,
          "icon": "layers.png",
          "name": "Satellite Imagery",
          "sliderRange":100,
        }
        tempCatalogueData.push(openStreetMap);
        tempCatalogueData.push(satelliteImagery);
        setCataloguedata(tempCatalogueData);
      })
      .catch((error) => {
        setApiError(
          error &&
            error.response &&
            error.response.data["header"] &&
            error.response.data["header"]["errorText"]
            ? defaultErrorMessage +
                " : " +
                error.response.data["header"]["errorText"]
            : defaultErrorMessage
        );
      });
     }

  useEffect(() => {
    if(layerBounds?._events.remove.length > 0){
      getAvailableDataSets(layerBounds?._bounds._northEast.lat,layerBounds?._bounds._northEast.lng,layerBounds?._bounds._southWest.lat,layerBounds?._bounds._southWest.lng ).then((data) => {
        setExpandLayers(data?.catalogueHeadings)
      })
    } else if(removelayerBounds?._events.remove.length === 0) {
      setExpandLayers([])
    }
  },[layerBounds, removelayerBounds])


  useEffect(() => {
    setEnableDnd((prevState) => !prevState);
  }, []);

  useEffect(() => {
    populateDndData();
  }, [CatalogueData])

  
  function populateDndData() {
    var finalResult = populateMenuItems(CatalogueData);
    var res = finalResult.map((elem) => {
      elem.text = elem.name;
      elem.layerId = elem.id;
      elem.isInActiveLayers = false;
      delete elem.name;
      return elem;
    })
    setDndData(res);
    // Add layer id to map (if present, from data page)
    if (state?.addLayerToMap) {
      var node = res.find((d) => d.id === rowField.layerCatalogueId);
      if (node) {
        handleSelect(node);
      }
    }
  }

  function populateMenuItems(currentSet, typographyValue = 0, parentId = 0, result = []) {
    for (const item of currentSet) {
      const itemKeys = Object.keys(item);
      let newItem;
      newItem = {
        parent: parentId,
        droppable: typographyValue < 2,
      };
      for (const itemKey of itemKeys) {
        if (
          Array.isArray(item[itemKey]) &&
          (itemKey === "catalogueHeadings" ||
            itemKey === "catalogueItems" ||
            itemKey === "layers")
        ) {
          populateMenuItems(
            item[itemKey],
            typographyValue + 1,
            item.id,
            result
          );
        } else {
          newItem[itemKey] = item[itemKey];
        }
      }
      if (typographyValue >= 0) {
        result.push(newItem);
      }
    }
    return result.sort((a, b) => a.parent - b.parent);
  }

  useEffect(() => {
    if(expandLayers) {
      var finalExpanLayers = populateMenuItems(expandLayers)
      var res = finalExpanLayers.map((elem) => {
        elem.text = elem.name;
        delete elem.name;
        return elem;
      })
      setHighLightText(res)
      setOpenLayer(res.map((_item)=>_item.parent))
    }
  },[expandLayers])


  useEffect(() => {
    let obj = {
      "parent": 0,
      "droppable": false,
      "id": "addddddb-b8ce-463d-9ecf-a4d719c11900",
      "description": "Open street Map",
      "active": 1,
      "icon": "layers.png",
      "text": "Open street Map",
  }
  setDndActiveLayers([{...obj, parent: 'edd6122b-b8ce-463d-9ecf-a4d719c02fc0', isSelected:true}, ...dndActiveLayers])
  setSelectedNodes([obj, ...selectedNodes]);
  },[])

  const handleDrop = (newTree) => {
    if (newTree.find(d => d.text == 'Active Layers' && d.parent == 0)) {
      setDndActiveLayers(newTree)
      var newSelectedNodes = [];
      newTree.forEach(data => {
        // re-order on map if layer is ticked (in active layers panel)
        if (data.text != 'Active Layers' && data.isSelected) {
          newSelectedNodes.push(selectedNodes.find(n => n.id === data.id));
        }
      })
      setSelectedNodes(newSelectedNodes)
    }
  };

  const handleSelect = (node) => {
    const item = dndActiveLayers?.find((n) => n.id === node.id);
    if (!item) {
      // Add to active layers panel
      setDndActiveLayers([{...node, parent: 'edd6122b-b8ce-463d-9ecf-a4d719c02fc0', isSelected: true, isInActiveLayers: true }, ...dndActiveLayers]);
      // Add to map
      setSelectedNodes([node, ...selectedNodes]);
    } else {
      // Remove from active layers panel
      setDndActiveLayers(dndActiveLayers?.filter((n) => n.id !== node.id));
      // Remove from map
      setSelectedNodes(selectedNodes?.filter((n) => n.id !== node.id));
    }
  };

  const handleSelectActiveLayers = (node) => {
    var activeLayers = dndActiveLayers;
    var activeLayerIndex = activeLayers.findIndex(d => d.id === node.id);
    activeLayers[activeLayerIndex].isSelected = !activeLayers[activeLayerIndex].isSelected;
    if (!activeLayers[activeLayerIndex].isSelected) {
      setSelectedNodes(selectedNodes?.filter((n) => n.id !== node.id));
    } else {
      var newSelectedNodes = [];
      activeLayers.forEach(layer => {
        if (layer.isSelected) {
          newSelectedNodes.push(layer)
        }
      })
      setSelectedNodes(newSelectedNodes);
    }
    setDndActiveLayers(activeLayers);
  };

  const removeActiveLayer = (nodeId) => {
    // Remove from active layers panel
    setDndActiveLayers(dndActiveLayers?.filter((n) => n.id !== nodeId));
    // Remove from map
    setSelectedNodes(selectedNodes?.filter((n) => n.id !== nodeId));
  };

  return (
    <div className="pt-3">
      <DndProvider backend={MultiBackend} options={getBackendOptions()}>
        <div className="app">
          <Tree
            tree={dndActiveLayers}
            classes={{root: 'active-layers-ul'}}
            rootId={0}
            initialOpen={true}
            render={(node, { depth, isOpen, onToggle }) => (
              <CustomNode
                node={node}
                depth={depth}
                isOpen={isOpen}
                isSelected={node?.isSelected}
                onToggle={onToggle}
                onSelect={handleSelectActiveLayers}
                selectedNodes={selectedNodes}
                setSelectedNodes={setSelectedNodes}
                layerStyleMap={layerStyleMap}
                layerFilterMap={layerFilterMap}
                removeActiveLayer={removeActiveLayer}
                toastZoomLevel = {toastZoomLevel}
                activeLayer={true}
              />
            )}
            onDrop={handleDrop}
            sort={false}
            insertDroppableFirst={false}
            dropTargetOffset={10}
            canDrop={(tree, { dragSource, dropTargetId }) => {
              if (dragSource?.parent === dropTargetId) {
                return true;
              }
            }}
            dragPreviewRender={(monitorProps) => (
              <div data-testid="custom-drag-preview" className="custom-drag-preview">
                {monitorProps.item.text}
              </div>
            )}
            placeholderRender={(node, { depth }) => (
              <div
                className="custom-drag-placeholder"
                style={{ left: depth * 24 }}
                data-testid="placeholder"
              ></div>
            )}
          />
          <Tree
            tree={dndData}
            classes={{root: 'catalogue-layers-ul'}}
            rootId={0}
            canDrag={() => enableDnd}
            canDrop={() => {
              if (!enableDnd) {
                return false;
              }
            }}
            initialOpen={OpenLayer}
            render={(node, { depth, isOpen, onToggle }) => (
              <CustomNode
                node={node}
                depth={depth}
                isOpen={isOpen}
                isSelected={!!dndActiveLayers?.find((n) => n?.id === node?.id)}
                onToggle={onToggle}
                onSelect={handleSelect}
                selectedNodes ={selectedNodes}
                setSelectedNodes = {setSelectedNodes}
                layerStyleMap={layerStyleMap}
                layerFilterMap={layerFilterMap}
                removeActiveLayer={removeActiveLayer}
                activeLayer={!!dndActiveLayers?.find((n) => n?.id === node?.id)}
                expandLayers={expandLayers}
                highLightText={highLightText}
                toastZoomLevel = {toastZoomLevel}
              />
            )}
            onDrop={handleDrop}
            dragPreviewRender={(monitorProps) => (
              <div data-testid="custom-drag-preview" className="custom-drag-preview">
                {monitorProps.item.text}
              </div>
            )}
          />
        </div>
      </DndProvider>
    </div>
  );
};
