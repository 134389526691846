import React, { useState, useRef } from "react";
import "./Navbar.scss";
import {
  Navbar,
  Row,
  Col,
  Button,
  Overlay,
  Popover,
  Form,
} from "react-bootstrap";
import Logo from "../../assets/images/Sensore_Logo_SmallHeader.png";
import Select from "react-select";
import { FiSearch } from "react-icons/fi";
import dropPin from '../../assets/images/map-pin.svg'
import { getNamedMapsheet, isFloat } from "../../Service/mapService";
import L from "leaflet";

const NavbarMapTop = ({ map }) => {
  const [showMapsheetSearch, setShowMapsheetSearch] = useState(false);
  const [target, setTarget] = useState(null);
  const [mapsheetSelects, setMapsheetSelects] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [enableSearch, setEnableSearch] = useState(false);
  const [marker, setMarker] = useState(null);
  const [enableSearchText, setEnableSearchText] = useState(false);
  const [searchLatitude, setSearchLatitude] = useState(false);
  const ref = useRef(null);

  const SEARCH_DEFAULT_ZOOM_LEVEL = 7;

  const handleClick = (e) => {
    setShowMapsheetSearch(!showMapsheetSearch);
    if (!showMapsheetSearch && marker) {
      setSearchValue(null);
      marker.remove();
    }
    setTarget(e.target);
  };

  const handleFormChange = (value) => {
    // If search value is coordinate based
    // Format is :<latitude>,<longitude>
    // eg. :-14.5,125.25
    if (value?.startsWith(":")) {
      setEnableSearch(false);
      setEnableSearchText("");
      var speratorIndex = value.indexOf(",");
      if (speratorIndex && speratorIndex > 1) {
        var lat = value.slice(1, speratorIndex).trim();
        var lon = value.slice(speratorIndex + 1).trim();
        if (isFloat(lat) && isFloat(lon)) {
          setSearchLatitude(false);
          if (lat > -90 && lat < 90 && lon > -180 && lon < 180) {
            setSearchValue({
              value: "lat-long",
              label: value,
              cordinates: {
                longitude: parseFloat(lon),
                latitude: parseFloat(lat),
              },
            });
            // Only enable the search button when coordinates are valid values.
            setEnableSearch(true);
          } else {
            setSearchLatitude(true);
            setEnableSearchText(
              "Please enter a valid coordinate. Latitude ranges: -90 and 90. Longitude ranges: -180 and 180"
            );
            setSearchValue(null);
          }
        } else if (isFloat(lat) && lon === "") {
          console.log("User is still to enter the longtitude");
        } else {
          setSearchValue(null);
          setSearchLatitude(true);
          setEnableSearchText(
            "Please enter a valid coordinate. The latitude and longitude should be numbers."
          );
        }
      }
    } else if (value?.trim() !== "") {
      // Text search, query backend for possible matches
      getNamedMapsheet(value).then((data) => {
        setMapsheetSelects(
          data.mapsheets.map((i) => ({
            label: i.name,
            value: i.code,
            cordinates: {
              longitude: i.longitude,
              latitude: i.latitude,
            },
          }))
        );
      });
      setEnableSearch(false);
      setEnableSearchText("");
    } else {
      setMapsheetSelects([]);
      setEnableSearchText("");
    }
    return value;
  };

  const handleSelectChange = (value) => {
    if (value) {
      setEnableSearch(true);
      setSearchValue(value);
    } else {
      setSearchValue(null);
    }
  };

  const handleNoOptions = (inputValue) => {
    if (inputValue?.startsWith(":")) {
      return "Enter the coordinate as: latitude,longtitue. Eg. :-38.03078,145.01390";
    } else if (!inputValue || inputValue.trim() === "") {
      setSearchLatitude(false);
      return "Enter a Mapsheet name or ':' to start search by coordinate.";
    }
    return "No matching name found";
  };

  const submitSearch = (e) => {
    e.preventDefault();
    var pointMarker;
    pointMarker = L.marker(
      [searchValue.cordinates.latitude, searchValue.cordinates.longitude],
      { title: searchValue.label }
    );
    setMarker(pointMarker);
    pointMarker.addTo(map);
    pointMarker.bindPopup(`${searchValue.label} code: ${searchValue.value}`);
    map.setView(
      [searchValue.cordinates.latitude, searchValue.cordinates.longitude],
      SEARCH_DEFAULT_ZOOM_LEVEL
    );
    setShowMapsheetSearch(false);
    setSearchValue(null);
    setEnableSearch(false);
    setMapsheetSelects([]);
  };

  return (
    <Navbar className="bgSen-navBarBackground" style={{ padding: "0px" }}>
      <Row style={{ width: "100%" }}>
        <Col md={10} className="backgroundImage">
          <Navbar.Brand>
            <img src={Logo} alt={"senoreLogo"} />
          </Navbar.Brand>
        </Col>
        <Col md={2} className="searchIcon">
          <div ref={ref}>
            <Button
              ref={target}
              onClick={handleClick}
              style={
                showMapsheetSearch
                  ? { backgroundColor: "black", border: "transparent" }
                  : { backgroundColor: "#472e45", border: "transparent" }
              }
              className="searchIconButton"
            >
              <img src={dropPin} alt="MapLocation" />
            </Button>
            <Overlay
              target={target}
              show={showMapsheetSearch}
              placement="right"
              container={ref}
              rootClose
            >
              <Popover id="popover-mapsheetSearch" className="searchTooltip">
                <Popover.Header as="h3">Search Name or Lat/Lon</Popover.Header>
                <Popover.Body>
                  <Row>
                    <Col>
                      <Select
                        className={
                          searchLatitude ? "disableOptions" : "basic-single"
                        }
                        autoFocus
                        menuIsOpen
                        isSearchable={true}
                        isClearable={true}
                        options={mapsheetSelects}
                        value={searchValue}
                        placeholder=""
                        onChange={(value) => handleSelectChange(value)}
                        onInputChange={(value) => handleFormChange(value)}
                        noOptionsMessage={({ inputValue }) =>
                          handleNoOptions(inputValue)
                        }
                      />
                      <Button
                        disabled={!enableSearch}
                        onClick={submitSearch}
                        className={
                          enableSearch ? "searchBtnIcon" : "disableBtnIcon"
                        }
                      >
                        <FiSearch />
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ color: "red" }}>{enableSearchText}</Col>
                  </Row>
                </Popover.Body>
              </Popover>
            </Overlay>
          </div>
        </Col>
      </Row>
    </Navbar>
  );
};

export default NavbarMapTop;
