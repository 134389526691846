
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import NavbarMapTop from '../Components/NavbarTop/NavbarMapTop';
import { NavbarMapVertical } from '../Components/NavbarVertical/NavbarMapVertical';

export const MapLayout = ({
  map,
  selectedNodes,
  setSelectedNodes,
  layerStyleMap,
  layerFilterMap,layerBounds,removelayerBounds, toastZoomLevel, rowField, setDownwardContinuationActivate}) => {
 
  return (
    <>
      <div className='mainLayout bgSen-primary' style={{overflow: "auto"}}>
        <div className='container-fluid'>
          <Row>
            <Col md={3} style={{ padding: "0px" }}>
              <NavbarMapTop map = {map} />
                <NavbarMapVertical  
                  setSelectedNodes={setSelectedNodes}
                  selectedNodes={selectedNodes}
                  layerStyleMap={layerStyleMap}
                  layerFilterMap={layerFilterMap}
                  layerBounds={layerBounds}
                  removelayerBounds={removelayerBounds}
                  toastZoomLevel = {toastZoomLevel}
                  rowField={rowField} 
                  setDownwardContinuationActivate={setDownwardContinuationActivate}
                  />
            </Col>
            <Col md={9} style={{ padding: "0px" }}>
              <Outlet />
            </Col>
          </Row>
        </div>
      </div></>
  )
}


