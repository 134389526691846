import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { CgPentagonRight } from "react-icons/cg";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import "./DownwardContinuationPage.scss";

export const DownwardContinuationSplitPane = (rowField) => {
  const navigate = useNavigate()
  return (
    <>
      <Container>
        <Row>
          <Col className="dataHeader">
            <CgPentagonRight />
            <p>Actions</p>
          </Col>
          {Object.keys(rowField.rowField).length === 0 &&
          rowField.rowField.constructor === Object ? (
            <Row>
              <Col>
                <div className="info-box">
                  <Row>
                    <Col xs={2}><AiOutlineInfoCircle size={24} style={{color: '#00bdbd'}}/></Col>
                    <Col xs={10}>Select one or more items to view relevant actions</Col>
                  </Row>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col className="rowButton d-grid gap-2">
              <Button style={{fontWeight: 'bold'}} onClick={() => navigate("/", { state: { addLayerToMap: true } })}>View on Map</Button>
              </Col>
            </Row>
          )}
        </Row>
      </Container>
    </>
  );
};
