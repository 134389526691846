import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AccountPage } from "../Pages/AccountPage/AccountPage";
import { DataPage } from "../Pages/DataPage/DataPage";
import { DownwardContinuationPage } from "../Pages/DownwardContinuationPage/DownwardContinuationPage";
import Login from "../Pages/LoginPage/LoginPage";
import { MapPage } from "../Pages/MapPage/MapPage";
import { ProjectPage } from "../Pages/ProjectPage/ProjectPage";
import { MapLayout } from "./MapLayouts";

const Layouts = () => {
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [map, setMap] = useState(false);
  const [layerStyleMap] = useState({});
  const [layerFilterMap] = useState({});
  const [layerBounds, setLayerBounds] = useState(null);
  const [removelayerBounds, setRemoveLayerBounds] = useState(null);
  const [toastZoomLevel, setToastZoomLevel] = useState(5);
  const [rowField, setRowField] = useState({});

  const [downwardContinuationActivate, setDownwardContinuationActivate] =
    useState(false);

  const setMainMap = (newMap) => {
    console.log("setting map...");
    setMap(newMap);
  };

  function hasJWT() {
    let flag = false;

    //check user has JWT token
    localStorage.getItem("token") ? (flag = true) : (flag = false);

    return flag;
  }

  return (
    <>
          <Routes>
         
            <Route
              element={ hasJWT() ? 
                <MapLayout
                  map={map}
                  selectedNodes={selectedNodes}
                  setSelectedNodes={setSelectedNodes}
                  layerStyleMap={layerStyleMap}
                  layerFilterMap={layerFilterMap}
                  layerBounds={layerBounds}
                  removelayerBounds={removelayerBounds}
                  toastZoomLevel={toastZoomLevel}
                  rowField={rowField}
                  setDownwardContinuationActivate={
                    setDownwardContinuationActivate
                  } 
                />
                : <Navigate to="/loginpage" />
              }
            >
              <Route
                path="/"
                element={ hasJWT() ? 
                  <MapPage
                    map={map}
                    setMap={setMainMap}
                    selectedNodes={selectedNodes}
                    layerStyleMap={layerStyleMap}
                    layerFilterMap={layerFilterMap}
                    setLayerBounds={setLayerBounds}
                    setRemoveLayerBounds={setRemoveLayerBounds}
                    setToastZoomLevel={setToastZoomLevel}
                  />
                  : <Navigate to="/loginpage" />
                }
              />

              <Route path="/accountpage" element={hasJWT() ? <AccountPage /> : <Navigate to="/loginpage" />}></Route>
              <Route
                path="/datapage"
                element={ hasJWT() ?
                  <DataPage
                    rowField={rowField}
                    setRowField={setRowField}
                    downwardContinuationActivate={downwardContinuationActivate}
                  />
                  : <Navigate to="/loginpage" />
                }
              ></Route>
              <Route
                path="/downwardcontinuationpage"
                element={ hasJWT() ?
                  <DownwardContinuationPage
                    rowField={rowField}
                    setRowField={setRowField}
                  />
                  : <Navigate to="/loginpage" />
                }
              ></Route>
              <Route  path="/projectpage" element={ hasJWT() ? <ProjectPage /> : <Navigate to="/loginpage" />}></Route>
            </Route>
          </Routes>
        <Routes>
          <Route path="/loginpage" element={<Login/>} />
        </Routes>
    </>
  );
};

export default Layouts;
