import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import DownwardContinuationCard from "../../Components/DownwardContinuationCard/DownwardContinuationCard";

export const DownwardContinuationPage = ({rowField, setRowField}) => {
  return (
    <Container className="mt-4 px-4">
      <Row>
        <Col>
          <DownwardContinuationCard rowField={rowField} setRowField={setRowField}/>
        </Col>
      </Row>
    </Container>
  );
};
